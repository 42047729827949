import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  SBAddIcon,
  SBInput,
  SBModal,
  Stack,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import { type CreateAreaInput, useCreateArea } from 'src/components/Particles';
import { AddAreaSchema } from './addArea.schema';

export function AddArea() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createAreaMutation } = useCreateArea({
    onCompleted: (data) => {
      handleCloseModal(data.createArea.message);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateAreaInput>({
    mode: 'all',
    resolver: yupResolver(AddAreaSchema),
  });

  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    reset();
    onClose();
  };

  const onConfirm = (data: CreateAreaInput) => {
    void createAreaMutation({
      variables: {
        createAreaInput: data,
      },
    });
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add Area
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBAddIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={
          <Stack gap={4} as={'form'}>
            <HStack gap={2}>
              <SBInput
                label="City"
                placeholder="ex. Al Ain"
                {...register('city')}
                error={errors.city?.message}
                crossOrigin={undefined}
              />
              <SBInput
                label="City Code*"
                placeholder="ex. AIN"
                {...register('standardCityName')}
                error={errors.standardCityName?.message}
                crossOrigin={undefined}
              />
            </HStack>
            <HStack gap={2}>
              <SBInput
                label="Zone*"
                placeholder="ex. Alain"
                {...register('zone')}
                error={errors.zone?.message}
                crossOrigin={undefined}
              />
              <SBInput
                label="Zone Code"
                placeholder="ex. ALN"
                {...register('standardZoneName')}
                error={errors.standardZoneName?.message}
                crossOrigin={undefined}
              />
            </HStack>
            <HStack gap={2}>
              <SBInput
                label="Area*"
                placeholder="ex. aflaj"
                {...register('standardAreaName')}
                error={errors.standardAreaName?.message}
                crossOrigin={undefined}
              />
              <SBInput
                label="Area ID*"
                placeholder="ex. 13"
                {...register('areaId')}
                error={errors.areaId?.message}
                crossOrigin={undefined}
              />
            </HStack>
            <Controller
              name="pickupEligible"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <HStack spacing={5}>
                  <Checkbox onChange={onChange} ref={ref} isChecked={value} size={'sm'}>
                    Is Pickup Eligible
                  </Checkbox>
                </HStack>
              )}
            />

            <Controller
              name="outOfZone"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <HStack spacing={5}>
                  <Checkbox onChange={onChange} ref={ref} isChecked={value} size={'sm'}>
                    Is Out of Zone
                  </Checkbox>
                </HStack>
              )}
            />

            <Controller
              name="remote"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <HStack spacing={5}>
                  <Checkbox onChange={onChange} ref={ref} isChecked={value} size={'sm'}>
                    Is Remote
                  </Checkbox>
                </HStack>
              )}
            />
          </Stack>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
