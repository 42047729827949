import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconWText,
  SBInput,
  SBModal,
  SBTrashIcon,
  Spinner,
  Stack,
  Text,
  toast,
  Tooltip,
  useDisclosure,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import {
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
  useState,
  type KeyboardEvent,
  type ReactElement,
} from 'react';
import SVG from 'react-inlinesvg';
import PackageIcon from 'src/assets/icons/Development/package.svg';
import scanSuccessSound from 'src/assets/scanSuccessSound.mp3';
import { openOrdersShippingLabels } from 'src/components/Pages/Orders/Utils/pdf.helper';
import {
  DEFAULT_COORDINATES,
  isDropDate,
  pendingSLALabel,
  useAddOrdersToJob,
  useAssignOrder,
  useCreateOrdersJob,
  useOrderByTrackingNumberQuery,
  useOrderSelection,
  useReceiveOrders,
} from 'src/components/Particles';
import { type OrdersStatusFilter } from '../../../order.types';
import { statusNameMask } from '../../Filters';
import { AssignDriver } from 'src/components/Organisms';
import LocationWarningIcon from 'src/assets/icons/orders/locationWarning.svg';

interface OpenElementProps {
  onClick: () => void;
}

interface ScanOrdersProps {
  openElement?: ReactElement<OpenElementProps>;
}
const successSound = new Audio(scanSuccessSound);

export function ScanOrders(props: ScanOrdersProps) {
  const { openElement } = props;
  const [swftboxTracking, setSwftboxTracking] = useState('');
  const [jobId, setJobId] = useState('');
  const { selectedOrders, select, unselect } = useOrderSelection();
  const [flashSuccessBorder, setFlashSuccessBorder] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { createOrdersJob, loading: submittingOrdersJob } = useCreateOrdersJob();
  const { receiveOrders, loading: receivingOrders } = useReceiveOrders();
  const { assignOrder } = useAssignOrder();
  const { addOrdersToJob } = useAddOrdersToJob();

  const { getOrderByTrackingNumber, loading } = useOrderByTrackingNumberQuery({
    onCompleted: ({ OrderByTrackingNumber }) => {
      if (!selectedOrders.has(OrderByTrackingNumber.id)) {
        select(OrderByTrackingNumber);
        successSound.play().catch(console.log);
        setFlashSuccessBorder(true);
        setTimeout(() => {
          setFlashSuccessBorder(false);
        }, 1000);
      }
      setSwftboxTracking('');
    },
  });

  function goToPrintScreen() {
    void openOrdersShippingLabels(Array.from(selectedOrders.values()));
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const value = e.currentTarget.value ?? '';
        const text = value.indexOf('<>') > 0 ? value.slice(0, value.indexOf('<>')) : value;
        if (text.startsWith('BIN')) return;
        getOrderByTrackingNumber(text);
      }
    },
    [getOrderByTrackingNumber]
  );

  const onSubmitComplete = (message: string) => {
    toast.success(message);
    onClose();
  };

  const createRouteForSelectedOrders = () => {
    const orderIds = [...selectedOrders.values()].map((order) => order.id);
    void createOrdersJob({ onCompleted: onSubmitComplete, ids: orderIds });
  };

  const onCompleted = (message: string) => {
    toast.success(message);
    close();
  };

  const onDriverAssignment = useCallback(
    async (driverId: string) => {
      const orderIds = [...selectedOrders.values()].map((order) => order.id);
      if (!orderIds.length) return false;
      try {
        if (jobId) {
          void addOrdersToJob({
            onCompleted,
            input: {
              orderIds,
              routeId: jobId,
            },
          });
          onClose();
          return true;
        }
        const response = await assignOrder({
          payload: {
            orderIds,
            driverId,
          },
        });
        toast.success(response.data?.createQuickRoute.message);
        onClose();
        return true;
      } catch {
        return false;
      }
    },
    [assignOrder, jobId]
  );

  const receiveSelectedOrders = () => {
    const ordersSwftboxTracking = [...selectedOrders.values()].map(
      (order) => order.swftboxTracking
    );
    void receiveOrders({
      onCompleted: onSubmitComplete,
      receiveOrdersInput: {
        ordersSwftboxTracking,
      },
    });
  };

  const UsedOpenElement = useMemo(() => {
    if (!isValidElement(openElement)) {
      return (
        <Button bg="#63C3EC" color="#fff" onClick={onOpen} fontWeight="bolder" fontSize="x-small">
          Scan
        </Button>
      );
    }

    return cloneElement<OpenElementProps>(openElement, {
      onClick: onOpen,
    });
  }, [onOpen, openElement]);

  return (
    <>
      {UsedOpenElement}
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="general"
        size="4xl"
        header={
          <Box mt="4" mb="3">
            <Box bg="gray.100" borderRadius="8px" p="3">
              <Box color="primary.700" fontSize="text-lg">
                <SBInput
                  label="Scanning Orders"
                  placeholder="Start Scanning..."
                  onKeyDown={handleKeyDown}
                  value={swftboxTracking}
                  onChange={(e) => {
                    setSwftboxTracking(e.target.value);
                  }}
                  crossOrigin={undefined}
                />
              </Box>
            </Box>
          </Box>
        }
        body={
          <Box
            bg="gray.100"
            borderRadius="8px"
            p="3"
            _after={{
              border: '5px solid #00B976',
              content: flashSuccessBorder ? "''" : false,
              position: 'fixed',
              right: 0,
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Grid
              gridTemplateColumns="repeat(12,1fr) 110px 50px"
              color="gray.700"
              fontWeight="semibold"
              borderBottom="1px solid"
              borderColor="gray.500"
              gap="3"
              py="3"
              mb="5"
            >
              <GridItem colSpan={4}> Reference</GridItem>
              <GridItem colSpan={4}>Request</GridItem>
              <GridItem colSpan={4}>Expected Time</GridItem>
              <GridItem>Status</GridItem>
              <GridItem></GridItem>
            </Grid>
            {selectedOrders.size > 0 ? (
              Array.from(selectedOrders.values()).map((order) => (
                <Grid
                  gridTemplateColumns="repeat(12,1fr) 110px 50px"
                  key={order.id}
                  gap="3"
                  color="primary.900"
                  fontWeight="medium"
                  fontSize="x-small"
                  _notLast={{ mb: '5' }}
                >
                  <GridItem colSpan={4}>
                    <Box>
                      <Text>{order.reference}</Text>
                      <Text>{order.retailer.name}</Text>
                      <Text color="gray.500">{order.swftboxTracking}</Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Text>{order.isReverse ? 'Return' : 'Delivery'}</Text>
                    <Stack direction={['column', 'row']} alignItems="center">
                      <Tooltip label="Collect on delivery" hasArrow>
                        <Box>
                          <IconWText
                            text={order.paymentAmount?.toString() ?? '0'}
                            Icon={<span>{order.currency}</span>}
                          />
                        </Box>
                      </Tooltip>
                      <span>-</span>
                      <Tooltip label="Package Count" hasArrow>
                        <Box>
                          <IconWText
                            text={order.packageCount?.toString()}
                            Icon={<SVG src={PackageIcon} width="15px" stroke="currentColor" />}
                          />
                        </Box>
                      </Tooltip>
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <HStack fontSize="x-small" w="100%" color="primary.700" fontWeight="semibold">
                      {!order?.timing?.expectedPickupDateStart &&
                        !order?.timing?.expectedDropDateStart &&
                        !order?.timing?.slaStart && <Text>-</Text>}
                      {!!isDropDate({
                        isReverse: order?.isReverse,
                        pickup: order?.timing?.expectedPickupDateStart,
                        drop: order?.timing?.expectedDropDateStart,
                      }) &&
                        order?.timing?.slaStart && (
                          <>
                            <Text fontWeight="bold">
                              {format(
                                isDropDate({
                                  isReverse: order?.isReverse,
                                  pickup: order?.timing?.expectedPickupDateStart,
                                  drop: order?.timing?.expectedDropDateStart,
                                }),
                                'HH:mm'
                              )}{' '}
                              -{' '}
                              {format(
                                isDropDate({
                                  isReverse: order?.isReverse,
                                  pickup: order?.timing?.expectedPickupDateEnd,
                                  drop: order?.timing?.expectedDropDateEnd,
                                }),

                                'HH:mm'
                              )}
                            </Text>
                            <Text fontSize="x-small" fontWeight="semibold" pe="8px">
                              {format(
                                isDropDate({
                                  isReverse: order?.isReverse,
                                  pickup: order?.timing?.expectedPickupDateEnd,
                                  drop: order?.timing?.expectedDropDateEnd,
                                }),
                                'MMM dd, yyyy'
                              )}
                            </Text>
                          </>
                        )}
                    </HStack>
                    <HStack fontSize="x-small" w="100%" color="gray.500">
                      <Text>SLA</Text>
                      {!order?.timing?.slaStart && <Text>{pendingSLALabel(order)}</Text>}
                      {order?.timing?.slaStart && order?.timing?.slaEnd && (
                        <>
                          <Text>
                            {format(new Date(order?.timing?.slaStart), 'HH:mm')} -{' '}
                            {format(new Date(order?.timing?.slaEnd), 'HH:mm')}
                          </Text>
                          <Text>{format(new Date(order?.timing?.slaEnd), 'MMM dd, yyyy')}</Text>
                        </>
                      )}
                    </HStack>
                    <HStack justifyContent="start" fontSize="8px" w="100%" color="gray.500">
                      <Text>Type</Text>
                      <Text>{order.isRemote ? 'Remote' : order?.dropProfile?.name}</Text>
                    </HStack>
                  </GridItem>
                  <GridItem alignItems="center">
                    <HStack>
                      <Badge
                        py="2px"
                        fontWeight="medium"
                        borderRadius="8px"
                        width="110px"
                        textAlign="center"
                        fontSize="x-small"
                        bg={statusNameMask(order?.status as OrdersStatusFilter).bg}
                        color={statusNameMask(order?.status as OrdersStatusFilter).color}
                      >
                        {statusNameMask(order?.status as OrdersStatusFilter).name}
                      </Badge>
                      {order.failedAttemptCount > 1 && <Text>X{order.failedAttemptCount}</Text>}
                      {DEFAULT_COORDINATES.some(
                        (loc) =>
                          loc.latitude === order.to?.coordinates?.latitude &&
                          loc.longitude === order.to?.coordinates?.longitude
                      ) && (
                        <Tooltip label={'No coordinates found for this order'}>
                          <Box>
                            <SVG src={LocationWarningIcon} width="14px" height="14px" />
                          </Box>
                        </Tooltip>
                      )}
                    </HStack>
                    {order.failedAttemptCount >= 3 && (
                      <Badge
                        mt="1"
                        py="2px"
                        fontWeight="medium"
                        borderRadius="8px"
                        width="110px"
                        textAlign="center"
                        fontSize="x-small"
                        bg="#F6DCFF"
                        color="#6764EC"
                        border="1.5px solid #6764EC"
                        textTransform="capitalize"
                      >
                        Eligible for RTO
                      </Badge>
                    )}
                  </GridItem>
                  <GridItem display="flex" alignItems="center" justifyContent="end" px="5px">
                    <Box color="gray.500" _hover={{ color: '#FF3A6E' }}>
                      <SBTrashIcon
                        onClick={() => {
                          unselect(order.id);
                        }}
                        width="15px"
                        cursor="pointer"
                      />
                    </Box>
                  </GridItem>
                </Grid>
              ))
            ) : (
              <Box textAlign="center" py="5" color="gray.700" fontWeight="semibold">
                {loading ? <Spinner /> : <>No Order Scanned</>}
              </Box>
            )}
          </Box>
        }
        footer={
          <HStack justifyContent="space-between" w="100%">
            <Box fontSize="text-sm" fontWeight="semibold">
              Selected Orders ({selectedOrders.size})
            </Box>
            <HStack>
              <Flex gap={12}>
                <Flex>
                  <Button
                    mx={1}
                    onClick={goToPrintScreen}
                    disabled={!selectedOrders.size}
                    variant="outline"
                    isLoading={submittingOrdersJob}
                  >
                    Shipping labels
                  </Button>
                  <Button
                    mx={1}
                    variant="outline"
                    onClick={createRouteForSelectedOrders}
                    isLoading={submittingOrdersJob}
                    disabled={!selectedOrders.size}
                  >
                    Create Route
                  </Button>
                </Flex>
                <Flex>
                  <AssignDriver
                    entityName="order"
                    onAssign={onDriverAssignment}
                    showExistingRoutes={true}
                    handleSelectedJob={setJobId}
                    orders={Array.from(selectedOrders.values())}
                  >
                    <Button mx={1} disabled={!selectedOrders.size}>
                      Assign
                    </Button>
                  </AssignDriver>
                  <Button
                    mx={1}
                    onClick={receiveSelectedOrders}
                    isLoading={receivingOrders}
                    disabled={!selectedOrders.size}
                  >
                    Receive
                  </Button>
                </Flex>
              </Flex>
            </HStack>
          </HStack>
        }
      />
    </>
  );
}
