import { Helmet } from 'react-helmet';
import {
  ErrorBoundary,
  OrderSelectionProvider,
  OrderFiltersProvider,
  OrderModalProvider,
  ScanningModeProvider,
} from 'src/components/Particles';
import { OrdersPage } from './orders.page';

export function OrdersPageWrapper() {
  return (
    <>
      <Helmet>
        <title> Orders </title>
      </Helmet>
      <ErrorBoundary>
        <OrderFiltersProvider>
          <OrderSelectionProvider>
            <OrderModalProvider>
              <ScanningModeProvider>
                <OrdersPage />
              </ScanningModeProvider>
            </OrderModalProvider>
          </OrderSelectionProvider>
        </OrderFiltersProvider>
      </ErrorBoundary>
    </>
  );
}
