import scanSuccessSound from 'src/assets/scanSuccessSound.mp3';
import scanStartSound from 'src/assets/startscanning.wav';
import scanInSound from 'src/assets/scan-in.wav';
import scanOutSound from 'src/assets/scan-out.wav';
import scanBinSound from 'src/assets/selectbin.wav';
import scanRestartSound from 'src/assets/restart.wav';
import scanFlagSound from 'src/assets/flagcscheckstarRTO.wav';

export type SoundType = 'success' | 'start' | 'in' | 'out' | 'bin' | 'restart' | 'flag';

const soundMap: Record<SoundType, string> = {
  success: scanSuccessSound,
  start: scanStartSound,
  in: scanInSound,
  out: scanOutSound,
  bin: scanBinSound,
  restart: scanRestartSound,
  flag: scanFlagSound,
};

const audioCache: Record<SoundType, HTMLAudioElement | undefined> = {
  success: undefined,
  start: undefined,
  in: undefined,
  out: undefined,
  bin: undefined,
  restart: undefined,
  flag: undefined,
};

function getAudioElement(type: SoundType): HTMLAudioElement | null {
  if (!audioCache[type]) {
    try {
      audioCache[type] = new Audio(soundMap[type]);
    } catch (error) {
      console.error(`Error loading sound for type "${type}":`, error);
      return null;
    }
  }
  return audioCache[type] ?? null;
}

export function playSound(type: SoundType): void {
  const sound = getAudioElement(type);
  if (sound) {
    sound.play().catch(console.log);
  } else {
    console.log(`Sound type "${type}" is not defined.`);
  }
}

type FlagAction =
  | 'flagged_incorrect_address_0_1'
  | 'flagged_incorrect_phone_0_1'
  | 'flagged_customer_refused_0_1'
  | 'flagged_update_payment_0_1'
  | 'flagged_missing_docs_items_0_1'
  | 'flagged_out_of_zone_0_1';

const actionMapping: Record<FlagAction, string> = {
  flagged_incorrect_address_0_1: 'Incorrect address',
  flagged_incorrect_phone_0_1: 'Incorrect phone number',
  flagged_customer_refused_0_1: 'Customer refused',
  flagged_update_payment_0_1: 'Update payment type',
  flagged_missing_docs_items_0_1: 'Missing documents/items',
  flagged_out_of_zone_0_1: 'Out of zone',
};

export function mapFlagToReason(flagAction: string) {
  return actionMapping[flagAction as FlagAction];
}
