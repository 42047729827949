import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { IMPERSONATE_RETAILER } from './retailers.graphql';
import { LoginResponse } from '../auth';

interface ImpersonateResponse {
  impersonateRetailer: LoginResponse;
}

interface QueryVariables {
  retailerId: string;
}

export function useImpersonate() {
  const [mutation, { data, loading, error }] = useMutation<ImpersonateResponse>(IMPERSONATE_RETAILER);

  const impersonate = useCallback(
    (variables: QueryVariables) => {
      return mutation({
        variables,
      });
    },
    [mutation]
  );

  return {
    impersonate,
    loading,
    error,
    user: data?.impersonateRetailer?.user || null,
    token: data?.impersonateRetailer?.token || null,
  };
}
