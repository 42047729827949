import { Table, Tbody, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { useMemo, type FC } from 'react';
import { type DropProfile } from 'src/components/Particles';
import { sortDropProfiles } from '../utilities';
import DropProfileRow from './SuperDropProfileRow';

interface DropProfileProps {
  dropProfiles: DropProfile[];
  retailerId: string;
  groupId: string;
  onEditDropProfile: (dropProfile: DropProfile) => void;
  onViewDetails: (dropProfile: DropProfile) => void;
}

const tableHeaders = [
  { title: 'Name', subtitle: '', id: 'name' },
  { title: 'Type', subtitle: '', id: 'type' },
  { title: 'Cuttof time', subtitle: '', id: 'cutoffTime' },
  { title: 'Configured Cities', subtitle: '', id: 'billingSlab' },
  { title: 'Status', subtitle: '', id: 'isDefault' },
  { title: '', subtitle: '', id: 'actions' },
];

const DropProfileTable: FC<DropProfileProps> = ({
  dropProfiles,
  retailerId,
  onEditDropProfile,
  onViewDetails,
  groupId,
}) => {
  const returnDropProfiles = useMemo(
    () => dropProfiles.filter((dropProfile) => dropProfile.isReturn),
    [dropProfiles]
  );
  const sortedReturnDropProfiles = useMemo(
    () => sortDropProfiles(returnDropProfiles),
    [returnDropProfiles]
  );
  const nonReturnDropProfiles = useMemo(
    () => dropProfiles.filter((dropProfile) => !dropProfile.isReturn),
    [dropProfiles]
  );
  const sortedNonReturnDropProfiles = useMemo(
    () => sortDropProfiles(nonReturnDropProfiles),
    [nonReturnDropProfiles]
  );

  return (
    <Table variant="striped" mb="8" mt="2" key={groupId}>
      <Thead>
        <Tr bg="#fbfbfc" color="neutral.normal">
          {tableHeaders.map((header) => (
            <Th key={header.id}>
              <Text>{header.title}</Text>
              <Text color="neutral.normal">{header.subtitle}</Text>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {sortedNonReturnDropProfiles.map((dropProfile, index) => (
          <DropProfileRow
            key={dropProfile.id}
            dropProfile={dropProfile}
            onEditDropProfile={onEditDropProfile}
            onViewDetails={onViewDetails}
            retailerId={retailerId}
            groupId={groupId}
            addDivider={
              index === sortedNonReturnDropProfiles.length - 1 &&
              sortedNonReturnDropProfiles.length > 0 &&
              sortedReturnDropProfiles.length > 0
            }
          />
        ))}
        {sortedReturnDropProfiles.map((dropProfile, index) => (
          <DropProfileRow
            key={dropProfile.id}
            dropProfile={dropProfile}
            onEditDropProfile={onEditDropProfile}
            onViewDetails={onViewDetails}
            retailerId={retailerId}
            groupId={groupId}
          />
        ))}
      </Tbody>
    </Table>
  );
};

export default DropProfileTable;
