import { gql } from '@apollo/client';

export const GET_BINS_QUERY = gql`
  query Bins {
    bins {
      id
      name
      description
      location
      dispatchZone
      label
    }
  }
`;

export const GET_BIN_QUERY = gql`
  query Bin($binId: UUID!) {
    bin(id: $binId) {
      id
      name
      description
      location
      dispatchZone
      label
    }
  }
`;

export const ADD_BIN_QUERY = gql`
  mutation CreateBin($createBinInput: CreateBinInput!) {
    createBin(createBinInput: $createBinInput) {
      message
    }
  }
`;
export const UPDATE_BIN_QUERY = gql`
  mutation UpdateBin($updateBinInput: UpdateBinInput!) {
    updateBin(updateBinInput: $updateBinInput) {
      message
    }
  }
`;

export const REMOVE_BIN_QUERY = gql`
  mutation RemoveBin($removeBinId: UUID!) {
    removeBin(id: $removeBinId) {
      message
    }
  }
`;
