import { useMemo, useEffect } from 'react';

import { Badge, Box, HStack, Text, Tooltip } from '@swftbox/style-guide';

import SVG from 'react-inlinesvg';
import PackageIcon from 'src/assets/icons/Development/package.svg';
import VerifyIcon from 'src/assets/icons/General/verify.svg';
import LocationWarningIcon from 'src/assets/icons/orders/locationWarning.svg';
import PartialVerifyIcon from 'src/assets/icons/General/partial-verify.svg';

import {
  DEFAULT_COORDINATES,
  DropProfileType,
  formatDate,
  type Order,
  type OrderFlag,
  OrderFlagStatus,
  OrderFlagType,
  useOrderTimelinesQuery,
} from 'src/components/Particles';

import { statusNameMask } from '../../Filters';
import { type OrdersStatusFilter } from '../../../order.types';

import FlagIcon from 'src/assets/icons/orders/flag.svg';
import LeaveAtTheDoorIcon from 'src/assets/icons/leaveAtDoor.svg';
import OversizedIcon from 'src/assets/icons/heavy.svg';
import ChilledIcon from 'src/assets/icons/chilled.svg';
import InternationalIcon from 'src/assets/icons/international 2.svg';
import RemoteIcon from 'src/assets/icons/remote.svg';

const removeNullFromString = (string: string) => string.replace(/(?:null\s*-|null|- null)/g, ' ');

function getFailedAttemptReason(timeline?: any[]) {
  if (!timeline) return '';
  const failedAttemptTimeline = timeline
    .filter((item) => item.action.toLowerCase() === 'failed')
    .sort((t1, t2) => (new Date(t1.date) > new Date(t2.date) ? -1 : 0))
    .at(0);
  if (!failedAttemptTimeline?.failedAttemptReason) return '';

  return failedAttemptTimeline.failedAttemptReason;
}

interface SortingModeContentProps {
  order?: Order;
  // setExternalSwftboxTracking?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

// const successSound = new Audio(scanSuccessSound);

export function SortingModeContent(props: SortingModeContentProps) {
  const { order } = props;

  // const inputRef = useRef<HTMLInputElement | null>(null);
  // const [swftboxTracking, setSwftboxTracking] = useState('');
  // const [order, setOrder] = useState<Order | null>(null);
  // const [flashSuccessBorder, setFlashSuccessBorder] = useState(false);

  const { getOrderTimeline, orderTimeline } = useOrderTimelinesQuery();

  useEffect(() => {
    if (order) {
      void getOrderTimeline({
        variables: {
          orderId: order.id,
        },
      });
    }
  }, [getOrderTimeline, order]);

  // const { receiveOrders } = useReceiveOrders();

  // const handleOrderFetchComplete = useCallback(
  //   ({ OrderBySwftboxTracking }: { OrderBySwftboxTracking: Order }) => {
  //     void getOrderTimeline({
  //       variables: {
  //         orderId: OrderBySwftboxTracking.id,
  //       },
  //     });
  //     if (setExternalSwftboxTracking) {
  //       setExternalSwftboxTracking(OrderBySwftboxTracking.swftboxTracking);
  //     }
  //     setOrder(OrderBySwftboxTracking);
  //     successSound.play().catch(console.log);
  //     setFlashSuccessBorder(true);
  //     setTimeout(() => {
  //       setFlashSuccessBorder(false);
  //     }, 1000);
  //     setSwftboxTracking('');
  //   },
  //   [getOrderTimeline, setExternalSwftboxTracking]
  // );

  // const { getOrderBySwftboxTracking, loading } = useOrderBySwftboxTrackingQuery({
  //   onCompleted: handleOrderFetchComplete,
  // });

  // const onReceiveConfirm = useCallback(
  //   (orderSwftboxTracking: string) => {
  //     void receiveOrders({
  //       receiveOrdersInput: {
  //         ordersSwftboxTracking: [orderSwftboxTracking],
  //       },
  //       onCompleted: (message) => {
  //         if (message) {
  //           toast.success(message);
  //         }
  //       },
  //     });
  //   },
  //   [receiveOrders]
  // );

  // const handleKeyDown = useCallback(
  //   (e: KeyboardEvent<HTMLInputElement>) => {
  //     if (e.key === 'Enter') {
  //       const value = e.currentTarget.value ?? '';
  //       const text = value.includes('<>') ? value.split('<>')[0] : value;
  //       if (text === 'mark_received_0_1' && order) {
  //         onReceiveConfirm(order.swftboxTracking);
  //       } else {
  //         getOrderBySwftboxTracking(text);
  //       }
  //     }
  //   },
  //   [getOrderBySwftboxTracking, onReceiveConfirm, order]
  // );

  // const handleFocusInput = useCallback(() => inputRef.current?.focus(), []);

  // useEffect(() => {
  //   if (isOpen && inputRef.current) {
  //     handleFocusInput();
  //   }

  //   return () => {
  //     setOrder(null);
  //     setSwftboxTracking('');
  //   };
  // }, [handleFocusInput, isOpen]);

  const { name, bg, border, color } = useMemo(
    () => statusNameMask((order?.status as OrdersStatusFilter) || ' '),
    [order]
  );

  const location = useMemo(() => order?.to, [order]);

  const isDefault = useMemo(() => {
    return DEFAULT_COORDINATES.some(
      (loc) =>
        loc.latitude === location?.coordinates?.latitude &&
        loc.longitude === location?.coordinates?.longitude
    );
  }, [location]);

  const expectedDateStart = useMemo(() => {
    return order?.isReverse
      ? order?.timing?.expectedPickupDateStart
      : order?.timing?.expectedDropDateStart;
  }, [order]);

  const expectedDateEnd = useMemo(() => {
    return order?.isReverse
      ? order?.timing?.expectedPickupDateEnd
      : order?.timing?.expectedDropDateEnd;
  }, [order]);

  const isStandard = useMemo(() => {
    return order?.dropProfile?.profileType === DropProfileType.standard;
  }, [order]);

  const oversizedComment = useMemo(() => {
    return order?.comments?.find((comment) => comment.type === 'OVERSIZED')?.comment ?? 'Oversized';
  }, [order]);

  const orderFlag = useMemo(() => {
    if (!order?.orderFlags) return null;

    return order.orderFlags.reduce((oldest: OrderFlag | null, current: OrderFlag) => {
      if (
        [OrderFlagType.cs, OrderFlagType.merchant].includes(current.flagType) &&
        current.status !== OrderFlagStatus.resolved &&
        (!oldest || new Date(current.createdAt) < new Date(oldest.createdAt))
      ) {
        return current;
      }
      return oldest;
    }, null);
  }, [order]);

  const isResolved = !orderFlag?.flagType || orderFlag.status === OrderFlagStatus.resolved;
  const flagColor = orderFlag?.flagType === OrderFlagType.merchant ? 'red' : '#32C6F1';

  return (
    <>
      <Box
        borderRadius="18px"
        border="5px solid #00B976"
        borderColor={order ? '#00B976' : '#ff3a6e'}
      >
        {/* <SBInput
          position="absolute"
          left="-9999px"
          placeholder="Start Scanning..."
          onKeyDown={handleKeyDown}
          ref={inputRef}
          value={swftboxTracking}
          onChange={(e) => {
            setSwftboxTracking(e.target.value);
          }}
          crossOrigin={undefined}
        /> */}

        <Box bg="gray.100" p="3" borderRadius="18px">
          {order ? (
            <Box boxShadow="xs" p="8px" w="100%">
              <Box>
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                  position="relative"
                >
                  <Text fontWeight="bold" fontSize="display-xl" width="calc(100% - 10px)">
                    {removeNullFromString(
                      `${location?.dispatchCity ?? ''} - ${location?.dispatchZone ?? ''}`
                    )}
                  </Text>
                </HStack>
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                  position="relative"
                >
                  <Box>
                    {expectedDateStart && expectedDateEnd && (
                      <>
                        {isStandard ? (
                          <Text fontSize="display-lg" fontWeight="bold">
                            Before {formatDate(expectedDateEnd, 'MMM dd, HH:mm')}
                          </Text>
                        ) : (
                          <Text fontSize="display-lg" fontWeight="bold">
                            {formatDate(expectedDateStart, 'MMM dd, HH:mm')} -{' '}
                            {formatDate(expectedDateEnd, 'HH:mm')}
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                  <Box>
                    {location?.isVerified && !isDefault && (
                      <Tooltip
                        label={`${
                          location?.verificationSource?.includes('Customer')
                            ? 'Verified by Customer'
                            : 'Partially Verified'
                        }`}
                        hasArrow
                      >
                        <Box>
                          <SVG
                            src={
                              location?.verificationSource?.includes('Customer')
                                ? VerifyIcon
                                : PartialVerifyIcon
                            }
                            width="40px"
                            height="40px"
                          />
                        </Box>
                      </Tooltip>
                    )}
                    {isDefault && (
                      <Tooltip label={'No coordinates found for this order'}>
                        <Box>
                          <SVG src={LocationWarningIcon} width="40px" height="40px" />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </HStack>
                <HStack justifyContent="start" alignItems="center">
                  {order.failedAttemptCount && (
                    <Badge
                      bg="#fff"
                      p="4"
                      borderRadius="4"
                      border="1px solid rgba(255, 101, 101, 1)"
                      color="rgba(255, 101, 101, 1)"
                      display="flex"
                      w="max-content"
                    >
                      <Text as="span" fontSize="text-xl" fontWeight="bold">
                        x {order.failedAttemptCount}
                      </Text>
                    </Badge>
                  )}
                  {order?.shouldLeaveAtTheDoor && (
                    <Tooltip label="Leave at the door" hasArrow>
                      <Box
                        p="2"
                        borderRadius="4"
                        display="flex"
                        w="max-content"
                        color="primary.900"
                      >
                        <SVG src={LeaveAtTheDoorIcon} width="25px" height="25px" />
                      </Box>
                    </Tooltip>
                  )}
                  {order.isRemote && (
                    <Tooltip label={'Remote'} hasArrow>
                      <Box
                        p="2"
                        borderRadius="4"
                        display="flex"
                        w="max-content"
                        color="primary.900"
                      >
                        <SVG src={RemoteIcon} width="25px" height="25px" />
                      </Box>
                    </Tooltip>
                  )}
                  {order.isInternational && (
                    <Tooltip label={'International'} hasArrow>
                      <Box
                        p="2"
                        borderRadius="4"
                        display="flex"
                        w="max-content"
                        color="primary.900"
                      >
                        <SVG src={InternationalIcon} width="25px" height="25px" />
                      </Box>
                    </Tooltip>
                  )}
                  {order.oversized && (
                    <Tooltip label={oversizedComment} hasArrow>
                      <Box
                        p="2"
                        borderRadius="4"
                        display="flex"
                        w="max-content"
                        color="primary.900"
                      >
                        <SVG src={OversizedIcon} width="25px" height="25px" />
                      </Box>
                    </Tooltip>
                  )}
                  {order.chilled && (
                    <Tooltip label={'Chilled Order'} hasArrow>
                      <Badge
                        bg="blue.500"
                        p="4"
                        borderRadius="4"
                        border="1px solid blue.500"
                        display="flex"
                        w="max-content"
                        color="primary.900"
                      >
                        <SVG src={ChilledIcon} width="20px" />
                      </Badge>
                    </Tooltip>
                  )}
                  <Badge
                    bg="rgba(255, 58, 110, 1)"
                    p="4"
                    borderRadius="4"
                    border="1px solid rgba(255, 58, 110, 1)"
                    color="#fff"
                    display="flex"
                    gap="1"
                    w="max-content"
                  >
                    <SVG src={PackageIcon} width="25px" stroke="currentColor" />
                    <Text as="span" fontSize="text-xl" fontWeight="bold">
                      {order?.packageCount?.toString() ?? ''}
                    </Text>
                  </Badge>
                  {order.failedAttemptCount >= 3 && (
                    <Badge
                      my="1.5"
                      py="2px"
                      fontWeight="medium"
                      borderRadius="8px"
                      px="3"
                      textAlign="center"
                      bg="#F6DCFF"
                      color="#6764EC"
                      fontSize="display-sm"
                      border="1.5px solid #6764EC"
                      textTransform="capitalize"
                    >
                      Eligible for RTO
                    </Badge>
                  )}
                </HStack>
                {!!getFailedAttemptReason(orderTimeline) && (
                  <Text
                    my="5"
                    fontSize="display-sm"
                    border="1px solid rgba(255, 101, 101, 1)"
                    color="rgba(255, 101, 101, 1)"
                    bg="#fff"
                    borderRadius="8px"
                    py="1"
                    px="2"
                    width="fit-content"
                    fontWeight="semibold"
                  >
                    {getFailedAttemptReason(orderTimeline)}
                  </Text>
                )}
                {orderFlag && (
                  <Badge
                    bg="#fff"
                    py="6"
                    borderRadius="8"
                    border="1px solid"
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    w="100%"
                    my="5"
                  >
                    <Box>
                      <SVG
                        src={FlagIcon}
                        width="30px"
                        fill={isResolved ? 'none' : flagColor}
                        stroke={isResolved ? 'currentColor' : flagColor}
                        style={{ margin: '10px 15px 10px 0px' }}
                      />
                    </Box>
                    <Text
                      as="span"
                      fontSize="display-xs"
                      fontWeight="semibold"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {orderFlag.flagReason}
                    </Text>
                  </Badge>
                )}
                <HStack spacing="0" position="relative">
                  <Text lineHeight="1.1" fontSize="display-md" noOfLines={2} mt="1" mb="1.5">
                    {(
                      (location?.building ?? '') +
                      ' ' +
                      (location?.addressLine1 ?? '') +
                      ' ' +
                      (location?.addressLine2 ?? '')
                    ).replace(/(?:null\s*-|null|- null)/g, ' ')}
                  </Text>
                </HStack>

                <Box>
                  {location?.area && location.area !== 'Not Mapped' && (
                    <Text lineHeight="1.1" fontSize="display-md" noOfLines={2} mt="1" mb="1.5">
                      {location?.area}
                    </Text>
                  )}
                  <Text fontSize="display-md">{location?.city}</Text>
                  <Text fontSize="display-md">{location?.country}</Text>
                </Box>
                <HStack
                  justifyContent="space-between"
                  alignItems="end"
                  w="100%"
                  position="relative"
                  my="2"
                >
                  <Badge
                    borderRadius="12"
                    py="5"
                    px="10"
                    textAlign="center"
                    fontSize="display-sm"
                    fontWeight="medium"
                    border="1px solid"
                    borderColor={border}
                    bg={bg}
                    color={color}
                    textTransform="capitalize"
                  >
                    {name}
                  </Badge>
                </HStack>
              </Box>
            </Box>
          ) : (
            <Box
              textAlign="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="300px"
              fontSize="display-lg"
              color="gray.700"
              fontWeight="semibold"
            >
              Please Start Scanning
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
