import {
  Box,
  SBModal,
  Text,
  useDisclosure,
  IconButton,
  SBTrashIcon,
  toast,
} from '@swftbox/style-guide';
import { useRemoveArea } from 'src/components/Particles';

interface DeleteAreaProps {
  name: string;
  id: string;
}

export function DeleteArea({ name, id }: DeleteAreaProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { removeAreaMutation } = useRemoveArea({
    onCompleted: ({ removeArea }) => {
      if (removeArea.success) {
        toast.success(removeArea.message);
        onClose();
      }
    },
  });

  const onConfirm = () => {
    void removeAreaMutation({
      variables: {
        removeAreaId: id,
      },
    });
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'red' }}
        aria-label="delete area"
        icon={<SBTrashIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete Area</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            Are You Sure About Deleting This Area ({name}) ?
          </Text>
        }
        size="lg"
        handleConfirm={onConfirm}
      />
    </>
  );
}
