import { Box, Grid } from '@swftbox/style-guide';
import { type Order } from 'src/components/Particles';
import StationItem from '../../../../../../../Molecules/StationItem/StationItem';

interface CustomerDetailsProps {
  order?: Order;
}

const cleanAddress = (address: string) => {
  const ignoredWords = ['null', 'undefined', 'NA', 'Not Mapped'];

  const ignoredRegex = new RegExp(`\\b(${ignoredWords.join('|')})\\b[.,-]*`, 'gi');

  return address
    .replace(ignoredRegex, '')
    .replace(/\s{2,}/g, ' ')
    .trim();
};

export const CustomerDetails = ({ order }: CustomerDetailsProps) => {
  const origin = {
    id: order?.from?.id ?? '',
    name: (order?.isReverse ? order.customer.name : order?.retailer.name) ?? '',
    phone: (order?.isReverse ? order.customer.phone : order?.retailer.phone) ?? '',
    isUser: !!order?.isReverse,
    address: `${
      (order?.from?.company ?? '') +
      ' ' +
      (order?.from?.addressLine1 ?? '') +
      ' ' +
      (order?.from?.addressLine2 ?? '') +
      ' ' +
      (order?.from?.building ?? '') +
      ', ' +
      (order?.from?.area ?? '') +
      ', ' +
      (order?.from?.city ?? '') +
      ', ' +
      (order?.from?.country ?? '') +
      ' ' +
      (order?.from?.countryCode ?? '')
    }`,
    lat: order?.from?.coordinates?.latitude ?? 0,
    lng: order?.from?.coordinates?.longitude ?? 0,
  };

  const destination = {
    id: order?.to?.id ?? '',
    name: (order?.isReverse ? order.retailer.name : order?.customer.name) ?? '',
    phone: (order?.isReverse ? order.retailer.phone : order?.customer.phone) ?? '',
    isUser: !order?.isReverse,
    address: `${
      (order?.to?.company ?? '') +
      ' ' +
      (order?.to?.addressLine1 ?? '') +
      ' ' +
      (order?.to?.addressLine2 ?? '') +
      ' ' +
      (order?.to?.building ?? '') +
      ', ' +
      (order?.to?.area ?? '') +
      ', ' +
      (order?.to?.city ?? '') +
      ', ' +
      (order?.to?.country ?? '') +
      ' ' +
      (order?.to?.countryCode ?? '')
    }`,
    lat: order?.to?.coordinates?.latitude ?? 0,
    lng: order?.to?.coordinates?.longitude ?? 0,
  };

  const stations = [origin, destination];
  return (
    <>
      {' '}
      <Box bg="gray.100" overflow="hidden" borderRadius="12px" border="1px solid #e9eaed">
        {/* <OrderDetailsMap origin={origin} destination={destination} /> */}
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']} m="40px" gap="20px">
          {stations.map((station) => (
            <StationItem
              key={station.id}
              name={station.name}
              phone={station.phone}
              address={cleanAddress(station.address)}
              isUser={station.isUser}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
};
