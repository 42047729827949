import { useMutation } from '@apollo/client';
import { removeTypeName } from 'src/components/Particles/Helpers';
import { type clientOptionType } from '../../../SharedTypes';
import { GET_BINS_QUERY, UPDATE_BIN_QUERY, type UpdateBinInput } from '../Queries';

interface UpdateBinResponse {
  message: string;
}

interface UpdateBinProps {
  payload: UpdateBinInput;
  onCompleted: (data: { updateBin: UpdateBinResponse }, clientOptions?: clientOptionType) => void;
}

export function useUpdateBin() {
  const [mutate, { error, loading }] = useMutation<
    { updateBin: UpdateBinResponse },
    { updateBinInput: UpdateBinInput }
  >(UPDATE_BIN_QUERY);

  async function updateBin({ payload, onCompleted }: UpdateBinProps) {
    return mutate({
      variables: { updateBinInput: removeTypeName(payload) },
      onCompleted,
      refetchQueries: [
        {
          query: GET_BINS_QUERY,
        },
      ],
    });
  }

  return {
    updateBin,
    error,
    loading,
  };
}
