import {
  Box,
  type BoxProps,
  Text,
  Grid,
  GridItem,
  type GridItemProps,
  type GridProps,
  type TextProps,
} from '@swftbox/style-guide';
import React from 'react';

const componentMap = {
  Box,
  Text,
  Grid,
  GridItem,
};

type ComponentMapKeys = keyof typeof componentMap;

export interface ComponentNode {
  type: ComponentMapKeys;
  props: (BoxProps | TextProps | GridProps | GridItemProps) & {
    children?: ComponentNode | ComponentNode[] | string;
  };
}

export const renderComponent = (node: ComponentNode | string): React.ReactNode => {
  if (typeof node === 'string') {
    return node;
  }

  const { type, props } = node;
  const Component = componentMap[type];

  if (!Component) {
    return null;
  }

  // Assuming you have a stable id or key in the props, use that instead of Math.random
  return (
    <Component key={props.key || Math.random()} {...props}>
      {Array.isArray(props.children)
        ? props.children.map(renderComponent)
        : renderComponent(props.children)}
    </Component>
  );
};
