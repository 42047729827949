import { useMutation } from '@apollo/client';
import { TOGGLE_ORDERS_BINS } from './orders.graphql';

interface ToggleOrdersBinResponse {
  message: string;
}

export interface ToggleOrdersBinInput {
  ids: string[];
  binLabel?: string;
}

interface ToggleOrdersBinProps {
  toggleOrdersToBinInput: ToggleOrdersBinInput;
  onCompleted: (message: string) => void;
}

export function useToggleOrdersBin() {
  const [mutate, { loading, error }] = useMutation<
    { toggleOrdersBin: ToggleOrdersBinResponse },
    { toggleOrdersToBinInput: ToggleOrdersBinInput }
  >(TOGGLE_ORDERS_BINS);

  async function toggleOrdersBin({ toggleOrdersToBinInput, onCompleted }: ToggleOrdersBinProps) {
    await mutate({
      variables: {
        toggleOrdersToBinInput,
      },
      onCompleted: (data) => {
        onCompleted(data.toggleOrdersBin.message);
      },
    });
  }

  return {
    toggleOrdersBin,
    loading,
    error,
  };
}
