import { useMutation } from '@apollo/client';
import {
  FILTER_ORDERS,
  FORCE_RTO_COMPLETE_ORDERS,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { type Order, type ForceRtoCompleteInput } from '../orders.types';

interface ForceRtoCompleteResponse {
  message: string;
  data: Order;
}

interface ForceRtoCompleteProps {
  payload: ForceRtoCompleteInput;
  onCompleted: (message: string) => void;
}

export function useForceRtoComplete() {
  const [mutate, { loading, error }] = useMutation<
    { forceRtoComplete: ForceRtoCompleteResponse },
    { forceRtoCompleteInput: ForceRtoCompleteInput }
  >(FORCE_RTO_COMPLETE_ORDERS);

  async function forceRtoCompleteOrders({ payload, onCompleted }: ForceRtoCompleteProps) {
    await mutate({
      variables: {
        forceRtoCompleteInput: payload,
      },
      onCompleted: ({ forceRtoComplete }) => {
        onCompleted(forceRtoComplete.message);
      },
    });
  }

  return {
    forceRtoCompleteOrders,
    loading,
    error,
  };
}
