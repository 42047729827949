import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import { CREATE_AREA, type CreateAreaInput } from '../Queries';

interface createAreaResponse {
  message: string;
}

interface CreateAreaProps {
  onCompleted: (data: { createArea: createAreaResponse }, clientOptions?: clientOptionType) => void;
}

export function useCreateArea({ onCompleted }: CreateAreaProps) {
  const [mutate, { error, loading }] = useMutation<
    { createArea: createAreaResponse },
    { createAreaInput: CreateAreaInput }
  >(CREATE_AREA, {
    onCompleted,
    refetchQueries: ['Areas'],
  });

  return {
    createAreaMutation: mutate,
    loading,
    error,
  };
}
