import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { type Bin } from 'src/components/Particles';
import { RemoveBin, UpdateBin, ViewBin } from './Modal';
import { QRCode } from 'react-qrcode-logo';

interface BinsTableProps {
  bins: Bin[];
}

export default function BinsTable(props: BinsTableProps) {
  const { bins } = props;

  if (!bins.length) return null;

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead>
          <Tr bg="#fbfbfc">
            <Th>Name</Th>
            <Th>Barcode Label</Th>
            <Th>Description</Th>
            <Th>Location</Th>
            <Th>Dispatch Zone</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {bins.map((bin) => {
            return (
              <Tr key={bin.id}>
                <Td>
                  <Text>{bin.name}</Text>
                </Td>
                <Td>
                  <QRCode value={bin.label} size={100} />
                  <Text>{bin.label}</Text>
                </Td>
                <Td>
                  <Text>{bin.description ?? '-'}</Text>
                </Td>
                <Td>
                  <Text>{bin.location ?? '-'}</Text>
                </Td>
                <Td>
                  <Text>{bin.dispatchZone ?? '-'}</Text>
                </Td>
                <Td>
                  <Flex gap={4} justifyContent="flex-end">
                    <ViewBin bin={bin} />
                    <UpdateBin bin={bin} />
                    <RemoveBin name={bin.name} id={bin.id} />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
