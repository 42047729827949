import { useMutation } from '@apollo/client';
import { CREATE_ORDER_FLAG, FILTER_ORDERS } from './orders.graphql';
import { type clientOptionType } from '../SharedTypes';
import type { OrderFlagInput, OrderFlag } from './orders.types';
import { QueriesStore } from '../queriesStore';

interface OrderFlagResponse {
  message: string;
  data: OrderFlag;
}

interface FlagOrderProps {
  payload: {
    orderFlagInput: OrderFlagInput;
  };
  onCompleted: (
    data: { createOrderFlag: OrderFlagResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useFlagOrder() {
  const [mutate, { loading, error }] = useMutation<
    { createOrderFlag: OrderFlagResponse },
    { orderFlagInput: OrderFlagInput }
  >(CREATE_ORDER_FLAG);

  async function flagOrder({ payload, onCompleted }: FlagOrderProps) {
    await mutate({
      variables: payload,
      onCompleted,
      // Missing Dto
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
        'FlaggedOrdersCount',
      ],
    });
  }

  return {
    flagOrder,
    loading,
    error,
  };
}
