import { Box, Button, Grid, Text, toast } from '@swftbox/style-guide';
import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  AttachmentLens,
  useAttachmentLensListQuery,
  useUpdateAttachmentStatus,
} from 'src/components/Particles';
import { OrderDetailsModal } from '../Orders/Components/Modal';
import { AttachmentFilters } from './Filters';
import { LensCard } from './LensCard';

export const OperationLensPage = () => {
  const [listAttachment, setListAttachment] = useState<AttachmentLens[]>([]);
  const [filters, setFilters] = useState<{
    status?: string[];
    photoLabelTags?: string[];
    driverIds?: string[];
    retailerIds?: string[];
    endDate?: Date;
    startDate?: Date;
  }>({
    status: ['pending'],
    photoLabelTags: undefined,
    driverIds: undefined,
    retailerIds: undefined,
    startDate: addDays(new Date(), -2),
    endDate: new Date(),
  });
  const [openModal, setOpenModal] = useState<{
    isModalOpen: boolean;
    attachment: null | AttachmentLens;
    currentIndex: number;
  }>({
    isModalOpen: false,
    attachment: null,
    currentIndex: 0,
  });
  const [page, setPage] = useState(1);
  const { mutate } = useUpdateAttachmentStatus();
  const { attachmentList, total, loading } = useAttachmentLensListQuery(
    {
      page,
      perPage: 20,
      commentType: ['DELIVERED', 'FAILED'],
      order: 'DESC',
      orderBy: 'createdAt',
      ...filters,
    },
    (data) => {
      if (data.paginatedAttachments) {
        setListAttachment([...listAttachment, ...data.paginatedAttachments.data]);
      }
    }
  );

  const handleAddMore = () => {
    setPage(page + 1);
  };

  const handleOpenModal = (index: number) => {
    setOpenModal({ isModalOpen: true, attachment: listAttachment[index], currentIndex: index });
  };

  const handleCloseModal = () => {
    setOpenModal({ isModalOpen: false, attachment: null, currentIndex: 0 });
  };
  const handleUpdateStatus = (status: 'passed' | 'rejected', id: string) => {
    mutate({
      variables: {
        updateAttachmentInput: {
          id,
          status,
        },
      },
      onCompleted: (data) => {
        const updatedAttachment = data.updateAttachmentStatus.data;
        setListAttachment((prevList) =>
          prevList.map((item) =>
            item.id === updatedAttachment.id ? { ...item, status: updatedAttachment.status } : item
          )
        );
        setOpenModal({
          ...openModal,
          attachment: {
            ...openModal.attachment,
            status: updatedAttachment.status,
          },
        });
        toast.success(data.updateAttachmentStatus.message);
      },
      onError: (error) => {
        console.error('Error updating status:', error);
      },
    });
  };
  useEffect(() => {
    setListAttachment([]);
    setPage(1);
  }, [JSON.stringify(filters)]);

  return (
    <Box mt="3">
      <Text color="gray.900" fontSize="display-xs" fontWeight="semibold" mb="6">
        Operation Lens Attachment
      </Text>
      <AttachmentFilters filters={filters} setFilters={setFilters} />
      <Box>
        <Grid
          gap="8"
          flexWrap="wrap"
          justifyContent={listAttachment?.length > 2 ? 'space-between' : 'auto'}
          gridTemplateColumns="repeat(auto-fit, 315px)"
        >
          {listAttachment.map((attachment, index) => (
            <LensCard
              key={attachment.id}
              attachment={attachment}
              setOpenModal={() => handleOpenModal(index)}
              handleUpdateStatus={handleUpdateStatus}
            />
          ))}
        </Grid>
      </Box>
      {loading && (
        <Box w="100%" textAlign="center">
          Loading...
        </Box>
      )}
      {!loading && attachmentList?.length === 0 && (
        <Box w="100%" textAlign="center">
          No Records Found{' '}
        </Box>
      )}
      <Box textAlign="center" w="100%" mt="64px" pb="12px">
        {!!total && !!listAttachment.length && total > listAttachment.length && (
          <Button mx="auto" variant="outline" onClick={handleAddMore} isLoading={loading}>
            Load more
          </Button>
        )}
      </Box>
      <OrderDetailsModal
        attachment={openModal?.attachment}
        isOpen={openModal.isModalOpen}
        handleClose={handleCloseModal}
        setCurrentIndex={(index) => {
          setOpenModal({ ...openModal, attachment: listAttachment[index], currentIndex: index });
        }}
        currentIndex={openModal.currentIndex}
        attachments={listAttachment}
        handleUpdateStatus={handleUpdateStatus}
        handleAddMore={handleAddMore}
        total={total}
      />
    </Box>
  );
};
