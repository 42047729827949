import { useMutation } from '@apollo/client';
import {
  FILTER_ORDERS,
  FORCE_DELIVER_ORDERS,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { UploadDirectory, useFileUpload } from '../../upload';
import { type Order, type CustomerForceDropInput } from '../orders.types';

interface ForceDeliverResponse {
  message: string;
  data: Order;
}

interface ForceDeliverProps {
  customerForceDropInput: CustomerForceDropInput;
  onCompleted: (message: string) => void;
}

export function useForceDeliver() {
  const [mutate, { loading, error }] = useMutation<
    { forceDeliver: ForceDeliverResponse },
    { customerForceDropInput: CustomerForceDropInput }
  >(FORCE_DELIVER_ORDERS);

  const { uploadFile } = useFileUpload({ directory: UploadDirectory.RECEIPTS });

  async function forceDeliverOrders({ customerForceDropInput, onCompleted }: ForceDeliverProps) {
    let receipt;
    if (customerForceDropInput.receipt instanceof File) {
      const { filePath } = await uploadFile(customerForceDropInput.receipt);
      receipt = filePath;
    }

    await mutate({
      variables: {
        customerForceDropInput: {
          ...customerForceDropInput,
          receipt,
        },
      },
      onCompleted: (data) => {
        onCompleted(data.forceDeliver.message);
      },
    });
  }

  return {
    forceDeliverOrders,
    loading,
    error,
  };
}
