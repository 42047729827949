import {
  Box,
  SBModal,
  Text,
  useDisclosure,
  IconButton,
  SBTrashIcon,
  toast,
} from '@swftbox/style-guide';
import { useRemoveBin } from 'src/components/Particles';

interface RemoveBinProps {
  name: string;
  id: string;
}

export function RemoveBin({ name, id }: RemoveBinProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { removeBin } = useRemoveBin();

  const onConfirm = () => {
    void removeBin({
      payload: { id },
      onCompleted: ({ removeBin }) => {
        if (removeBin.message) {
          toast.success(removeBin.message);
          onClose();
        }
      },
    });
  };
  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'red' }}
        aria-label="remove bin"
        icon={<SBTrashIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        body={
          <Text fontSize="text-md" color="gray.700">
            Are You Sure About Removing This Bin{' '}
            <Text as="span" fontWeight="semibold">
              {name}
            </Text>{' '}
            ?
          </Text>
        }
        size="lg"
        handleConfirm={onConfirm}
      />
    </>
  );
}
