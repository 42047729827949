import { useQuery } from '@apollo/client';
import { GET_BINS_QUERY, type Bin } from '../Queries';

interface BinsResponse {
  bins: Bin[];
}

export function useBins() {
  const { data, loading, error } = useQuery<BinsResponse>(GET_BINS_QUERY);
  return {
    bins: data?.bins ?? [],
    loading,
    error,
  };
}
