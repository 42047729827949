import { useMutation } from '@apollo/client';
import { ADD_COMMENT, GET_COMMENTS } from './comments.graphql';
import { type SaveCommentInput } from './comments.types';
import { QueriesStore } from '../queriesStore';
import { FILTER_ORDERS } from '../orders/orders.graphql';

interface AddCommentResponse {
  message: string;
}

interface CreateCommentPayload extends SaveCommentInput {
  onCompleted?: () => void;
}

export function useAddCommentMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    { createComment: AddCommentResponse },
    { createCommentInput: SaveCommentInput }
  >(ADD_COMMENT);

  async function addComment({ onCompleted, ...payload }: CreateCommentPayload) {
    await mutate({
      variables: {
        createCommentInput: payload,
      },
      onCompleted,
    });
  }

  return {
    addComment,
    message: data?.createComment.message,
    loading,
    error,
  };
}
