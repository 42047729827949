import { type RouteObject } from 'react-router-dom';
import { Settings } from 'src/components/Pages';
import {
  Categories,
  Configurations,
  Fulfillments,
  ChangePassword,
  BackupPage,
  TaskCategoriesPage,
  RolesAndPermissions,
  FeedbackQuestions,
  Carriers,
  Features,
  Invoices,
  Areas,
  Bins,
} from 'src/components/Pages/Settings/Components';
import { ErrorBoundary } from '../../ErrorBoundary';
import { Subscriptions } from 'src/components/Pages/Settings/Components/Subscriptions';

export const SettingsRoutes: RouteObject = {
  path: 'settings',
  element: (
    <ErrorBoundary>
      <Settings />
    </ErrorBoundary>
  ),
  children: [
    {
      path: 'task-categories',
      element: <TaskCategoriesPage />,
    },
    {
      path: 'configuration',
      element: <Configurations />,
    },
    {
      path: 'order-features',
      element: <Features />,
    },
    {
      path: 'user-roles',
      element: <RolesAndPermissions />,
    },
    {
      path: 'categories',
      element: <Categories />,
    },
    {
      path: 'fulfillments',
      element: <Fulfillments />,
    },
    {
      path: 'feedback',
      element: <FeedbackQuestions />,
    },
    {
      path: 'subscriptions',
      element: <Subscriptions />,
    },
    {
      path: 'password',
      element: <ChangePassword />,
    },
    {
      path: 'backups',
      element: <BackupPage />,
    },
    {
      path: 'carriers',
      element: <Carriers />,
    },
    {
      path: 'invoices',
      element: <Invoices />,
    },
    {
      path: 'areas',
      element: <Areas />,
    },
    {
      path: 'bins',
      element: <Bins />,
    },
  ],
};
