import { gql } from '@apollo/client';
export const CORE_COMMENTS_FRAGMENT = gql`
  fragment commentFragment on CommentDto {
    id
    userId
    orderId
    type
    comment
    attachments
    user {
      id
      name
    }
    userType
    createdAt
  }
`;
export const GET_COMMENTS = gql`
  ${CORE_COMMENTS_FRAGMENT}
  query Comments($orderId: UUID) {
    comments(orderId: $orderId) {
      ...commentFragment
    }
  }
`;

export const ADD_COMMENT = gql`
  ${CORE_COMMENTS_FRAGMENT}
  mutation CreateComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) {
      message
      data {
        id
        comment
        order {
          id
          comments {
            ...commentFragment
          }
        }
      }
    }
  }
`;
