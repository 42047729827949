import { memo } from 'react';
import { InternationalErrorsModal, InternationalShipment } from 'src/components/Organisms';
import {
  EditCustomerLocation,
  OrderDetailsModal,
} from 'src/components/Pages/Orders/Components/Modal';

const CellModals = () => {
  return (
    <>
      <EditCustomerLocation />
      <InternationalShipment />
      <InternationalErrorsModal />
      <OrderDetailsModal />
    </>
  );
};

export default memo(CellModals);
