import { yupResolver } from '@hookform/resolvers/yup';
import { Box, SBEditIcon, SBModal, useDisclosure, toast, IconButton } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useUpdateBin, type UpdateBinInput, type Bin } from 'src/components/Particles';
import { UpdateBinSchema } from '../../Validations';
import { BinForm } from '../../Form/BinForm';
import { useCallback } from 'react';

interface UpdateBinProps {
  bin: Bin;
}

export function UpdateBin({ bin }: UpdateBinProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateBin } = useUpdateBin();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateBinInput>({
    mode: 'all',
    defaultValues: {
      id: bin.id,
      name: bin.name,
      description: bin.description,
      dispatchZone: bin.dispatchZone,
      location: bin.location,
    },
    resolver: yupResolver(UpdateBinSchema),
  });

  const handleCloseModal = useCallback(
    (message?: string) => {
      if (message) {
        toast.success(message);
      }
      onClose();
      reset();
    },
    [onClose, reset]
  );

  const onConfirm = useCallback(
    (data: UpdateBinInput) => {
      void updateBin({
        payload: data,
        onCompleted: (data) => {
          handleCloseModal(data.updateBin.message);
        },
      });
    },
    [updateBin, handleCloseModal]
  );

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'blue' }}
        aria-label="update bin"
        icon={<SBEditIcon width="15px" />}
        onClick={onOpen}
      />

      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
          </Box>
        }
        size="2xl"
        body={<BinForm register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
