import {
  SBBuildingIcon,
  SBCreditCardIcon,
  SBDocumentIcon,
  SBEuroIcon,
  SettingIcon,
  User_circle_1Icon,
  SBMapIcon,
  SBUserIcon,
  SBChartHistogramIcon,
} from '@swftbox/style-guide';
import { type Key, type ReactElement } from 'react';

import SVG from 'react-inlinesvg';
import uuid from 'react-uuid';

import ShoppingBag04 from 'src/assets/icons/eCommerce/shopping-bag-01.svg';
import Car01 from 'src/assets/icons/General/car-01.svg';
import Route from 'src/assets/icons/General/route.svg';
import Fulfillment from 'src/assets/icons/General/target-04.svg';
import TaskIcon from 'src/assets/icons/General/tasks-icon.svg';
import InvoicesIcon from 'src/assets/icons/invoices-icon.svg';
import LensIcon from 'src/assets/icons/lens.svg';
export interface SidebarNavigateProps {
  id: Key;
  to: string;
  name: string;
  icon: ReactElement<any, any>;
  children?: Array<{
    id: Key;
    to: string;
    name: string;
    icon?: ReactElement<any, any>;
    end?: boolean;
    roles?: string[];
  }>;
  roles?: string[];
}

export const SidebarNavigate: SidebarNavigateProps[] = [
  {
    id: uuid(),
    to: '/',
    name: 'Drivers Tracking',
    icon: <SBMapIcon />,
  },
  {
    id: uuid(),
    to: '/orders',
    name: 'Orders',
    icon: (
      <SVG src={ShoppingBag04} stroke="currentColor" fill="transparent" className="stroke-hover" />
    ),
  },
  {
    id: uuid(),
    to: '/jobs',
    name: 'Jobs',
    icon: <SVG src={Route} stroke="currentColor" fill="transparent" className="stroke-hover" />,
  },
  {
    id: uuid(),
    to: '/fulfillment',
    name: 'Fulfillment',
    icon: (
      <SVG src={Fulfillment} stroke="currentColor" fill="transparent" className="stroke-hover" />
    ),
  },
  {
    id: uuid(),
    to: '/billing',
    name: 'Billing',
    icon: (
      <SVG
        width={'24px'}
        height={'24px'}
        src={InvoicesIcon}
        stroke="currentColor"
        fill="transparent"
        className="stroke-hover"
      />
    ),
  },
  {
    id: uuid(),
    to: '/payments',
    name: 'Payments',
    roles: ['SuperAdmin', 'Admin'],
    icon: <SBEuroIcon />,
    children: [
      {
        id: uuid(),
        to: '/payments',
        name: 'Payments',
        icon: <SettingIcon />,
        end: true,
      },
      {
        id: uuid(),
        to: '/payments/settlements',
        name: 'Settlements',
        icon: <SBEuroIcon />,
        end: true,
      },
    ],
  },
  {
    id: uuid(),
    to: '/expenses',
    name: 'Expenses',
    roles: ['SuperAdmin', 'Admin'],
    icon: <SBCreditCardIcon />,
    children: [
      {
        id: uuid(),
        to: '/expenses',
        name: 'Expenses',
        icon: <SettingIcon />,
        end: true,
      },
      {
        id: uuid(),
        to: `/expenses/settlements`,
        name: 'Settlements',
        icon: <SBCreditCardIcon />,
        end: true,
      },
    ],
  },
  {
    id: uuid(),
    to: '/reports',
    name: 'Reports',
    roles: ['SuperAdmin', 'Reporting'],
    icon: <SBDocumentIcon />,
  },
  {
    id: uuid(),
    to: '/analytics',
    name: 'Analytics',
    roles: ['SuperAdmin', 'Admin'],
    icon: <SBChartHistogramIcon />,
  },
  {
    id: uuid(),
    to: '/partners',
    name: 'Partners',
    roles: ['SuperAdmin', 'Admin', 'AccountManager'],
    icon: <SBBuildingIcon />,
  },
  {
    id: uuid(),
    to: '/tasks',
    name: 'Tasks',
    icon: (
      <SVG src={TaskIcon} width="24px" height="24px" stroke="currentColor" fill="currentColor" />
    ),
  },
  {
    id: uuid(),
    to: '/drivers',
    name: 'Drivers',
    icon: <SVG src={Car01} stroke="currentColor" fill="transparent" className="stroke-hover" />,
  },
  {
    id: uuid(),
    to: '/users',
    name: 'Users',
    roles: ['SuperAdmin', 'Admin', 'AccountManager'],
    icon: <User_circle_1Icon />,
  },
  {
    id: uuid(),
    to: '/operation-lens',
    name: 'Operation Lens',
    icon: <SVG src={LensIcon} stroke="currentColor" fill="transparent" className="stroke-hover" />,
  },
  {
    id: uuid(),
    to: '/settings',
    name: 'Settings',
    icon: <SettingIcon />,
    children: [
      {
        id: uuid(),
        to: '/settings/task-categories',
        name: 'Task Categories',
        roles: ['SuperAdmin', 'Admin'],
        end: true,
      },
      {
        id: uuid(),
        to: '/settings/categories',
        name: 'Categories',
        roles: ['SuperAdmin', 'Admin'],
        end: true,
      },
      {
        id: uuid(),
        to: `/settings/order-features`,
        name: 'Order Features',
        icon: <SBUserIcon />,
        roles: ['SuperAdmin', 'Admin'],
      },
      {
        id: uuid(),
        to: `/settings/fulfillments`,
        name: 'Fulfillments',
        roles: ['SuperAdmin', 'Admin'],
      },
      {
        id: uuid(),
        to: `/settings/configuration`,
        name: 'Configuration',
        end: true,
        roles: ['SuperAdmin'],
      },
      {
        id: uuid(),
        to: `/settings/user-roles`,
        name: 'Roles and Permissions',
        roles: ['SuperAdmin'],
      },
      {
        id: uuid(),
        to: `/settings/feedback`,
        name: 'Feedback Questions',
        end: true,
        roles: ['SuperAdmin', 'Admin'],
      },
      {
        id: uuid(),
        to: `/settings/subscriptions`,
        name: 'Subscriptions',
        end: true,
        roles: ['SuperAdmin', 'Admin'],
      },
      {
        id: uuid(),
        to: `/settings/password`,
        name: 'Change Password',
        end: true,
      },
      {
        id: uuid(),
        to: `/settings/backups`,
        name: 'Backups',
        end: true,
        roles: ['SuperAdmin'],
      },
      {
        id: uuid(),
        to: `/settings/carriers`,
        name: 'Carriers',
        roles: ['SuperAdmin', 'Admin'],
      },
      {
        id: uuid(),
        to: `/settings/invoices`,
        name: 'Invoices',
        roles: ['SuperAdmin', 'Admin'],
      },
      {
        id: uuid(),
        to: `/settings/areas`,
        name: 'Areas & Zones',
        roles: ['SuperAdmin', 'Admin'],
      },
      {
        id: uuid(),
        to: `/settings/bins`,
        name: 'Bins',
        roles: ['SuperAdmin', 'Admin'],
      },
    ],
  },
];
