import * as yup from 'yup';

export const CreateBinSchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  description: yup.string().nullable().optional(),
  location: yup.string().nullable().optional(),
  dispatchZone: yup.string().nullable().optional(),
});

export const UpdateBinSchema = yup.object().shape({
  id: yup.string().uuid('ID must be a valid UUID.').required('ID is required.'),
  name: yup.string().nullable().optional(),
  description: yup.string().nullable().optional(),
  location: yup.string().nullable().optional(),
  dispatchZone: yup.string().nullable().optional(),
});
