import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import { REMOVE_AREA_QUERY } from '../Queries';

interface removeAreaResponse {
  success: boolean;
  message: string;
}

interface DeleteAreaProps {
  onCompleted: (data: { removeArea: removeAreaResponse }, clientOptions?: clientOptionType) => void;
}

export function useRemoveArea({ onCompleted }: DeleteAreaProps) {
  const [mutate, { error, loading }] = useMutation<
    { removeArea: removeAreaResponse },
    { removeAreaId: string }
  >(REMOVE_AREA_QUERY, {
    onCompleted,
    refetchQueries: ['Areas'],
  });

  return {
    removeAreaMutation: mutate,
    loading,
    error,
  };
}
