import { useMutation } from '@apollo/client';
import type { AssignInternationalInput, Order } from '../orders.types';
import { ASSIGN_ORDER_INTERNATIONAL, FILTER_ORDERS } from '../orders.graphql';
import { QueriesStore } from '../../queriesStore';

interface AssignInternationalResponse {
  message: string;
  order: Order;
}

interface AssignInternationalProps {
  assignOrderInternational: AssignInternationalInput;
  onCompleted: (message: string) => void;
}

export function useAssignOrderInternational() {
  const [mutate, { loading, error }] = useMutation<
    { assignOrderInternational: AssignInternationalResponse },
    { assignOrderInternational: AssignInternationalInput }
  >(ASSIGN_ORDER_INTERNATIONAL);

  async function assignOrderInternational({
    assignOrderInternational,
    onCompleted,
  }: AssignInternationalProps) {
    await mutate({
      variables: {
        assignOrderInternational,
      },
      onCompleted: (data) => {
        onCompleted(data.assignOrderInternational.message);
      },
    });
  }

  return {
    assignOrderInternational,
    loading,
    error,
  };
}
