import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import { REMOVE_BIN_QUERY, GET_BINS_QUERY } from '../Queries';

interface RemoveBinResponse {
  message: string;
}

interface RemoveBinProps {
  payload: { id: string };
  onCompleted: (data: { removeBin: RemoveBinResponse }, clientOptions?: clientOptionType) => void;
}

export function useRemoveBin() {
  const [mutate, { loading, error }] = useMutation<
    { removeBin: RemoveBinResponse },
    { removeBinId: string }
  >(REMOVE_BIN_QUERY, {});

  async function removeBin({ payload, onCompleted }: RemoveBinProps) {
    const { id } = payload;
    await mutate({
      variables: {
        removeBinId: id,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_BINS_QUERY,
        },
      ],
    });
  }

  return {
    removeBin,
    loading,
    error,
  };
}
