import * as yup from 'yup';

export const AddAreaSchema = yup.object().shape({
  city: yup
    .string()
    .transform((value) => (value === '' ? undefined : value))
    .min(3, 'must be at least 3 characters')
    .max(20, 'must be at most 20 characters')
    .optional(),
  standardCityName: yup
    .string()
    .min(3, 'must be at least 3 characters')
    .max(20, 'must be at most 20 characters')
    .required('City Code is required'),
  zone: yup
    .string()
    .min(2, 'must be at least 2 characters')
    .max(20, 'must be at most 20 characters')
    .required('Zone is required'),
  standardZoneName: yup
    .string()
    .transform((value) => (value === '' ? undefined : value))
    .min(2, 'must be at least 2 characters')
    .max(20, 'must be at most 20 characters')
    .optional(),
  standardAreaName: yup
    .string()
    .min(3, 'must be at least 3 characters')
    .max(100, 'must be at most 100 characters')
    .required('Area is required'),
  areaId: yup
    .number()
    .min(501, 'Area ID must be more than 500')
    .required('Area ID is required')
    .typeError('Area ID must be a valid number'),
  outOfZone: yup.boolean().default(false),
  remote: yup.boolean().default(false),
  pickupEligible: yup.boolean().default(false),
});
