export interface NotificationTriggerInput {
  retailerId: string;
  triggersInput: { trigger: string; triggersType?: string }[];
}

export enum NotificationTrigger {
  inFulfillment = 'IN_FULFILLMENT',
  expressOrderRequested = 'EXPRESS_ORDER_REQUESTED',
  standardOrderRequested = 'STANDARD_ORDER_REQUESTED',
  standardOrderPickedUpFromPartner = 'STANDARD_ORDER_PICKED_UP_FROM_PARTNER',
  orderDelivered = 'ORDER_DELIVERED',
  orderAttempted = 'ORDER_ATTEMPTED',
  orderDelivering = 'ORDER_DELIVERING',
  returnOrderPickedUpFromCustomer = 'RETURN_ORDER_PICKED_UP_FROM_CUSTOMER',
  returnRequested = 'RETURN_REQUESTED',
  orderArrivingShortly = 'ORDER_ARRIVING_SHORTLY',
  orderArrivedAtCustomer = 'ORDER_ARRIVED_AT_CUSTOMER',
}
