import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import { ADD_BIN_QUERY, GET_BINS_QUERY, type AddBinInput } from '../Queries';

interface AddBinResponse {
  message: string;
}
interface AddBinProps {
  payload: AddBinInput;
  onCompleted: (data: { createBin: AddBinResponse }, clientOptions?: clientOptionType) => void;
}

export function useAddBin() {
  const [mutate, { loading, error }] = useMutation<
    { createBin: AddBinResponse },
    { createBinInput: AddBinInput }
  >(ADD_BIN_QUERY);

  async function addBin({ payload, onCompleted }: AddBinProps) {
    await mutate({
      variables: {
        createBinInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_BINS_QUERY,
        },
      ],
    });
  }

  return {
    addBin,
    loading,
    error,
  };
}
