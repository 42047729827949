import { useLazyQuery } from '@apollo/client';
import { JOB_INSIGHTS, type JobInsights } from '../Queries';

interface QueryResponse {
  RouteInsights: JobInsights;
}

export function useJobInsightsQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(JOB_INSIGHTS);

  function getJobInsights() {
    void query({ pollInterval: 30000 });
  }

  return {
    getJobInsights,
    insights: data?.RouteInsights,
    loading,
    error,
  };
}
