import {
  Box,
  IconButton,
  SBModal,
  useDisclosure,
  Text,
  SBEyeIcon,
  Stack,
  SBInput,
  Checkbox,
} from '@swftbox/style-guide';
import { type Area } from 'src/components/Particles';

interface ViewAreaProps {
  area: Area;
}
export function ViewArea({ area }: ViewAreaProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'blue' }}
        aria-label="view Area"
        icon={<SBEyeIcon width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEyeIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>View Area</Text>
          </Box>
        }
        size="xl"
        body={
          <Stack gap={4} as={'form'}>
            <SBInput
              label="Dispatch City Name"
              value={area.city}
              crossOrigin={undefined}
              isDisabled
            />
            <SBInput
              label="Dispatch City Code"
              value={area.standardCityName}
              crossOrigin={undefined}
              isDisabled
            />
            <SBInput
              label="Dispatch Zone Name"
              value={area.zone}
              crossOrigin={undefined}
              isDisabled
            />
            {area.standardZoneName && (
              <SBInput
                label="Dispatch Zone Code"
                value={area.standardZoneName}
                crossOrigin={undefined}
                isDisabled
              />
            )}
            <SBInput
              label="Dispatch Area"
              value={area.standardAreaName}
              crossOrigin={undefined}
              isDisabled
            />
            <SBInput label="Area ID" value={area.areaId} crossOrigin={undefined} isDisabled />

            <SBInput
              label="Country Code"
              value={area.countryCode}
              crossOrigin={undefined}
              isDisabled
            />

            <Checkbox isChecked={area.pickupEligible} size={'sm'} isDisabled>
              Is Pickup Eligible
            </Checkbox>
            <Checkbox isChecked={area.outOfZone} size={'sm'} isDisabled>
              Is Out of Zone
            </Checkbox>
            <Checkbox isChecked={area.remote} size={'sm'} isDisabled>
              Is Remote
            </Checkbox>
          </Stack>
        }
        bordered={false}
        footer={<></>}
      />
    </>
  );
}
