import { type FC } from 'react';
import {
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PencilIcon,
  SBEyeIcon,
  SBMenuDotsVerticalIcon,
  SBUserDeleteIcon,
  toast,
} from '@swftbox/style-guide';
import {
  type DropProfile,
  useRemoveDropProfileMutation,
  useRemovePrimary,
  useSetPrimary,
  useToggleProfileStatus,
} from 'src/components/Particles';
import BookmarkIcon from 'src/assets/icons/General/bookmark.svg';
import RemoveIcon from 'src/assets/icons/General/minus-icon.svg';
import ToggleStatusIcon from 'src/assets/icons/General/setting03.svg';

import SVG from 'react-inlinesvg';
interface Props {
  dropProfile: DropProfile;
  onEdit: (dropProfile: DropProfile) => void;
  onViewDetails: (dropProfile: DropProfile) => void;
  retailerId: string;
  groupId: string;
}

export const ActionButtons: FC<Props> = ({
  dropProfile,
  onEdit,
  onViewDetails,
  retailerId,
  groupId,
}) => {
  const { removeDropProfile, loading } = useRemoveDropProfileMutation();
  const { mutate: setPrimary } = useSetPrimary();
  const { mutate: removePrimary } = useRemovePrimary();
  const { mutate: setToggleStatus } = useToggleProfileStatus();
  const onDelete = async () => {
    if (loading) return;
    const confirmed = confirm('Are you sure you want to delete this drop profile?');
    if (!confirmed) {
      return;
    }
    const { data } = await removeDropProfile(dropProfile.id);
    toast.success(data?.removeDropProfile?.message);
  };

  const handleViewDetails = () => {
    onViewDetails(dropProfile);
  };
  const handleSetPrimary = () => {
    void setPrimary({
      variables: {
        markPrimaryInput: {
          id: dropProfile.id,
          retailerId,
          groupId,
        },
      },
    });
  };

  const handleRemovePrimary = () => {
    void removePrimary({
      variables: {
        removePrimaryInput: {
          id: dropProfile.id,
          retailerId,
          groupId,
        },
      },
    });
  };
  const handleToggleStatus = () => {
    void setToggleStatus({
      variables: {
        updateDropProfileInput: {
          id: dropProfile.id,
          isActive: !dropProfile.isActive,
        },
      },
    });
  };
  return (
    <Menu>
      <MenuButton as="button">
        <SBMenuDotsVerticalIcon width="15px" />
      </MenuButton>
      <MenuList minW="170px">
        <MenuItem onClick={handleViewDetails}>
          <IconWText text="View details" spacing="20px" Icon={<SBEyeIcon width="15px" />} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEdit(dropProfile);
          }}
        >
          <IconWText
            text="Edit"
            spacing="20px"
            Icon={<PencilIcon width="15px" viewBox="0 0 16 16" />}
          />
        </MenuItem>

        {!dropProfile.isDefault && (
          <MenuItem padding={2} onClick={onDelete}>
            <IconWText
              text="Delete"
              spacing="20px"
              color="red.normal"
              Icon={<SBUserDeleteIcon width="15px" />}
            />
          </MenuItem>
        )}
        {!dropProfile.isPrimary && dropProfile.isActive && (
          <MenuItem padding={2} onClick={handleSetPrimary}>
            <IconWText
              text="Set as primary"
              spacing="20px"
              Icon={<SVG src={BookmarkIcon} width="16px" stroke="currentColor" />}
            />
          </MenuItem>
        )}
        {dropProfile.isPrimary && (
          <MenuItem padding={2} onClick={handleRemovePrimary}>
            <IconWText
              text="Unset primary"
              spacing="20px"
              Icon={<SVG src={RemoveIcon} width="16px" stroke="currentColor" />}
            />
          </MenuItem>
        )}
        {!dropProfile.isPrimary && (
          <MenuItem padding={2} onClick={handleToggleStatus}>
            <IconWText
              text={dropProfile.isActive ? 'Disable' : 'Enable'}
              spacing="20px"
              Icon={<SVG src={ToggleStatusIcon} width="16px" stroke="currentColor" />}
            />
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
