import { SBInput, Stack } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';
import type { AddBinInput, UpdateBinInput } from 'src/components/Particles';

interface BinFormProps {
  register: UseFormRegister<UpdateBinInput | AddBinInput>;
  errors: FieldErrors<UpdateBinInput | AddBinInput>;
}

export function BinForm(props: BinFormProps) {
  const { register, errors } = props;

  return (
    <Stack gap={4}>
      <SBInput
        crossOrigin={undefined}
        label="Name*"
        placeholder="Add bin's name"
        {...register('name')}
        error={errors.name?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Description"
        placeholder="Add bin's description"
        {...register('description')}
        error={errors.description?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Location"
        placeholder="Add bin's location"
        {...register('location')}
        error={errors.location?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Dispatch Zone"
        placeholder="Add bin's dispatch zone"
        {...register('dispatchZone')}
        error={errors.dispatchZone?.message}
      />
    </Stack>
  );
}
