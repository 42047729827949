import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { QueriesStore } from '../queriesStore';
import { SYNC_ORDERS, FILTER_ORDERS } from './orders.graphql';

interface SyncOrdersResponse {
  message: string;
  success: boolean;
}

interface FixElasticOrdersInput {
  ordersSwftboxTracking?: string[];
  from?: string;
  to?: string;
}
interface SyncOrdersProps {
  payload: FixElasticOrdersInput;
  onCompleted?: (data: { fixOrdersElasticSync: SyncOrdersResponse }) => void;
}
export function useSyncOrders() {
  const [mutate, { loading, error }] = useMutation<
    { fixOrdersElasticSync: SyncOrdersResponse },
    { fixElasticOrdersInput: FixElasticOrdersInput }
  >(SYNC_ORDERS);

  const syncOrders = useCallback(
    async ({ payload, onCompleted }: SyncOrdersProps) => {
      return mutate({
        variables: {
          fixElasticOrdersInput: {
            ...payload,
          },
        },
        onCompleted,
        // Missing Dto
        refetchQueries: [
          {
            query: FILTER_ORDERS,
            variables: QueriesStore.get('FILTER_ORDERS'),
          },
        ],
      });
    },
    [mutate]
  );

  return {
    syncOrders,
    loading,
    error,
  };
}
