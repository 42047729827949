import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ScanningModeContext } from '../../Contexts';

export function ScanningModeProvider({ children }: { children: ReactNode }) {
  const [scannedValue, setScannedValue] = useState<string | null>(null);

  const clearScannedValue = useCallback(() => {
    setScannedValue(null);
  }, []);

  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const clipboardText = await navigator.clipboard.readText();
        if (clipboardText) {
          setScannedValue(clipboardText.trim());
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      scannedValue,
      setScannedValue,
      clearScannedValue,
    }),
    [scannedValue, clearScannedValue]
  );

  return (
    <ScanningModeContext.Provider value={contextValue}> {children}</ScanningModeContext.Provider>
  );
}
