interface ActionAddressHistory {
  [key: string]: string; // This is the index signature
  CREATED: string;
  MARKED_DEFAULT: string;
  MATCHED: string;
  UPDATED_FROM_CITY_GETTER: string;
  UPDATED_FROM_GEO_CODER: string;
  UPDATED_MANUALLY: string;
  VERIFIED_BY_CUSTOMER: string;
  CITY_GETTER_FAILED: string;
  GEO_CODER_FAILED: string;
}
export const actionAddressHistory: ActionAddressHistory = {
  CREATED: 'Auto',
  MARKED_DEFAULT: 'Auto',
  MATCHED: 'Auto',
  UPDATED_FROM_CITY_GETTER: 'CityGetter',
  UPDATED_FROM_GEO_CODER: 'Geocoder',
  UPDATED_MANUALLY: 'Customer',
  VERIFIED_BY_CUSTOMER: 'Customer',
  CITY_GETTER_FAILED: 'CityGetter',
  GEO_CODER_FAILED: 'Geocoder',
};

export const actionAddressTitle: ActionAddressHistory = {
  CREATED: 'Created',
  MARKED_DEFAULT: 'Marked Default',
  MATCHED: 'Updated',
  UPDATED_FROM_CITY_GETTER: 'Updated',
  UPDATED_FROM_GEO_CODER: 'Updated',
  UPDATED_MANUALLY: 'Manually Update',
  VERIFIED_BY_CUSTOMER: 'Verified By Customer',
  CITY_GETTER_FAILED: 'Failed',
  GEO_CODER_FAILED: 'Failed',
};
