import {
  Box,
  IconButton,
  SBModal,
  useDisclosure,
  SBEyeIcon,
  SBInput,
  Stack,
} from '@swftbox/style-guide';
import { type Bin } from 'src/components/Particles';

interface ViewBinProps {
  bin: Bin;
}

export function ViewBin({ bin }: ViewBinProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputProps = {
    crossOrigin: undefined,
    isDisabled: true,
  };

  const binFields = [
    { label: 'Name', value: bin.name },
    { label: 'Barcode Label', value: bin.label },
    { label: 'Description', value: bin.description },
    { label: 'Location', value: bin.location },
    { label: 'Dispatch Zone', value: bin.dispatchZone },
  ];

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'blue' }}
        aria-label="view bin"
        icon={<SBEyeIcon width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEyeIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
          </Box>
        }
        size="xl"
        body={
          <Stack gap={4}>
            {binFields.map(({ label, value }) => (
              <SBInput key={label} {...inputProps} label={label} value={value} />
            ))}
          </Stack>
        }
        bordered={false}
        footer={<></>}
      />
    </>
  );
}
