import { createContext, useContext } from 'react';

interface ScanningModeContextProps {
  scannedValue: string | null;
  setScannedValue: (value: string | null) => void;
  clearScannedValue: () => void;
}

export const ScanningModeContext = createContext<ScanningModeContextProps>({
  scannedValue: null,
  setScannedValue: console.info,
  clearScannedValue: console.info,
});

export function useScanningMode() {
  return useContext(ScanningModeContext);
}
