import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconWText,
  SBEditIcon,
  SBInput,
  SBModal,
  Stack,
  Text,
  toast,
  Tooltip,
  useDisclosure,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useUpdateOrder } from 'src/components/Particles';
import { UpdateOrderSchema } from './order.schema';
import SVG from 'react-inlinesvg';
import PackageIcon from 'src/assets/icons/Development/package.svg';

interface EditOrderProps {
  orderId: string;
  packageCount: number;
}

type UpdateOrderForm = Omit<EditOrderProps, 'orderId'>;

export function EditOrderPackageCount({ orderId, packageCount }: EditOrderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    reset,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<UpdateOrderForm>({
    mode: 'all',
    resolver: yupResolver(UpdateOrderSchema),
    defaultValues: {
      packageCount,
    },
  });

  const { updateOrder } = useUpdateOrder();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    onClose();
  };

  function onConfirm(data: UpdateOrderForm) {
    void updateOrder({
      payload: { ...data, id: orderId },
      onCompleted: ({ updateOrder }) => {
        handleCloseModal(updateOrder.message);
      },
    });
  }
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setFocus('packageCount'), 100); // set focus on the input field with the name 'packageCount'
    }
  }, [isOpen, setFocus]);
  return (
    <>
      <Box bg="#EFEFEF" px="2" borderRadius="4" w="max-content">
        <Tooltip label="Package Count" hasArrow>
          <Button variant="link" onClick={onOpen}>
            <IconWText
              text={`x${packageCount?.toString()}`}
              Icon={<SVG src={PackageIcon} width="15px" stroke="currentColor" />}
            />
          </Button>
        </Tooltip>
      </Box>
      <SBModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Edit Order</Text>
          </Box>
        }
        size="xl"
        body={
          <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={4}>
            <SBInput
              type="number"
              label="Package Count *"
              {...register('packageCount')}
              error={errors.packageCount?.message}
            />
          </Stack>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
