import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { type clientOptionType } from '../SharedTypes';
import { GET_ORDERS_BY_BIN_LABEL } from './orders.graphql';
import { type Order } from './orders.types';
import { type Bin } from '../setting';

interface BinWithOrder extends Bin {
  orders: Order[];
}

interface QueryResponse {
  binByLabel: BinWithOrder;
}

interface QueryProps {
  onCompleted?: (data: QueryResponse, clientOptions?: clientOptionType) => void;
}

export function useOrdersByBinLabelQuery({ onCompleted }: QueryProps) {
  const [query, { loading, error }] = useLazyQuery<QueryResponse>(GET_ORDERS_BY_BIN_LABEL, {
    onCompleted,
    fetchPolicy: 'no-cache',
  });

  const getOrdersByBinLabel = useCallback(
    (label: string) => {
      void query({ variables: { label } });
    },
    [query]
  );

  return {
    getOrdersByBinLabel,
    loading,
    error,
  };
}
