import { type ReactNode, useCallback, useState } from 'react';
import { OrdersSelectionContext } from 'src/components/Particles/Contexts/Order';
import { type Order } from 'src/components/Particles/resolvers';

export function OrderSelectionProvider({ children }: { children: ReactNode }) {
  const [selectedOrders, setSelectedOrders] = useState<Map<string, Order>>(new Map());
  const [detailedOrder, setDetailedOrder] = useState<Order>();

  const select = useCallback((order: Order) => {
    setSelectedOrders((prevSelectedOrders) => {
      const newSelections = new Map(prevSelectedOrders);
      newSelections.set(order.id, order);
      return newSelections;
    });
  }, []);

  const unselect = useCallback((id: string) => {
    setSelectedOrders((prevSelectedOrders) => {
      const newSelections = new Map(prevSelectedOrders);
      newSelections.delete(id);
      return newSelections;
    });
  }, []);

  const unselectAll = useCallback(() => {
    setSelectedOrders(new Map());
  }, []);

  const selectAll = useCallback((orders: Order[]) => {
    setSelectedOrders(() => {
      const newSelections = new Map(orders.map((order) => [order.id, order]));
      return newSelections;
    });
  }, []);

  const toggleDetailedOrder = useCallback(
    (order?: Order) => {
      setDetailedOrder(order);
    },
    [setDetailedOrder]
  );

  return (
    <OrdersSelectionContext.Provider
      value={{
        selectedOrders,
        select,
        unselect,
        unselectAll,
        toggleDetailedOrder,
        detailedOrder,
        selectAll,
      }}
    >
      {children}
    </OrdersSelectionContext.Provider>
  );
}
