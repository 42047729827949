import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { useBins } from 'src/components/Particles';
import BinsHeader from './Components/BinsHeader';
import BinsTable from './Components/BinsTable';
import style from './style.module.scss';

export const Bins = () => {
  const { bins, loading } = useBins();
  return (
    <Box className={style.bins}>
      <BinsHeader binsCount={bins.length} />
      <WithLoader isLoading={loading}>
        <BinsTable bins={bins} />
      </WithLoader>
      {!loading && bins.length === 0 && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Bins Found" />
        </Flex>
      )}
    </Box>
  );
};
