import {
  IconButton,
  SettingIcon,
  SBUserIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  toast
} from '@swftbox/style-guide';
import { useState } from 'react';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import { useConfiguration, type Retailer, fixPrecision, useImpersonate, useAuthContext, UserRoles } from 'src/components/Particles';
import { PartnerSettingDrawer } from '../Setting';
import { Config } from 'src/config';

interface PartnersTableProps {
  retailers: Retailer[];
  page: number;
  perPage: number;
  total: number;
  onPageChange: (page: number) => void;
}

export function PartnersTable(props: PartnersTableProps) {
  const { retailers, page, perPage, total, onPageChange } = props;
  const { user } = useAuthContext();
  const [selectedRetailerId, setSelectedRetailerId] = useState<string>();
  const { configuration: currency } = useConfiguration('currency');
  const { impersonate, loading } = useImpersonate();

  function openRetailer(retailerId: string) {
    setSelectedRetailerId(retailerId);
  }

  function closePartnerModal() {
    setSelectedRetailerId(undefined);
  }

  async function impersonateRetailer(retailerId: string) {
    console.log('retailer id', retailerId);
    const { data, errors } = await impersonate({ retailerId });
    if (errors?.length) {
      toast.error('Failed to get impersonation token');
      return;
    }

    const token = data?.impersonateRetailer?.token;
    const userId = data?.impersonateRetailer?.user?.id;

    window.open(`${Config.CONTROLROOM_URL}/login/?access_token=${token}&company_id=${retailerId}&user_id=${userId}`, '_blank');
  }

  if (!retailers.length) return null;

  return (
    <>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              <Th width="30%">Name</Th>
              <Th>PoD balance</Th>
              <Th>Status</Th>
              <Th>Fulfillment Center</Th>
              <Th width="20%">Plan</Th>
              <Th>Subscription</Th>
              <Th right="0">
                {' '}
                <SmallPaginate
                  totalOrders={total}
                  page={page ?? 1}
                  perPage={perPage ?? 10}
                  onPageChange={onPageChange}
                />
              </Th>{' '}
            </Tr>
          </Thead>
          <Tbody>
            {retailers.map((retailer) => {
              return (
                <Tr key={retailer.id}>
                  <Td>
                    <Text>{retailer.name}</Text>
                  </Td>
                  <Td>
                    <Text>{`${fixPrecision(retailer.orderBalance)} ${currency?.value}`}</Text>
                  </Td>
                  <Td>
                    <Text>{retailer.status}</Text>
                  </Td>
                  <Td>
                    <Text>{retailer.fulfillment?.name ?? 'N / A'}</Text>
                  </Td>
                  <Td>
                    <Text>{retailer.subscription?.plan.name ?? '-'}</Text>
                  </Td>
                  <Td>
                    <Text>{retailer.subscription?.status ?? '-'}</Text>
                  </Td>
                  <Td textAlign="end" minWidth={60}>
                    <IconButton
                      display="inline"
                      ml="auto"
                      size="lg"
                      variant="link"
                      color="gray.600"
                      _hover={{ opacity: '0.5', color: 'blue' }}
                      aria-label="view location"
                      onClick={() => {
                        openRetailer(retailer.id);
                      }}
                      icon={<SettingIcon width="18px" />}
                    />
                    {user?.roles?.includes(UserRoles.SUPERADMIN) &&
                      <IconButton
                        display="inline"
                        size="lg"
                        variant="link"
                        color="gray.600"
                        _hover={{ opacity: '0.5', color: 'blue' }}
                        aria-label="impersonate"
                        onClick={() => {
                          impersonateRetailer(retailer.id);
                        }}
                        icon={<SBUserIcon width="18px" />}
                        title="Impersonate"
                        disabled={loading}
                      />
                    }
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <PartnerSettingDrawer
        retailerId={selectedRetailerId}
        isOpen={!!selectedRetailerId}
        onClose={closePartnerModal}
      />
      {/* <PartnerModal
        retailerId={selectedRetailerId}
        isOpen={!!selectedRetailerId}
        onClose={closePartnerModal}
      /> */}
    </>
  );
}
