import { Button, Flex, HStack, Badge } from '@swftbox/style-guide';
import { type JobStatusFilter, type JobTypeFilter, useJobInsightsQuery } from 'src/components/Particles';
import { StatsMocking, TypesMocking } from '../mock';
import { useEffect } from 'react';

interface JobFilterProps {
  onStatusChange: (value?: JobStatusFilter) => void;
  statusFilter?: JobStatusFilter[];
  onTypeChange: (value?: JobTypeFilter) => void;
  typeFilter?: JobTypeFilter[];
  isExpressFilter?: boolean;
  hasChilledFilter?: boolean;
  onExpressFilter: (value?: boolean) => void;
  onChilledFilter: (value?: boolean) => void;
}
export function JobsFilter(props: JobFilterProps) {
  const {
    onStatusChange,
    onTypeChange,
    onExpressFilter,
    onChilledFilter,
    statusFilter,
    typeFilter,
    isExpressFilter,
    hasChilledFilter,
  } = props;

  const { getJobInsights, insights } = useJobInsightsQuery();

  useEffect(() => {
    getJobInsights();
  }, []);

  return (
    <Flex align="center">
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
        mr="2"
      >
        {TypesMocking.map((type) => (
          <Button
            key={type.header}
            variant="grouped"
            bg={typeFilter?.includes(type.value as JobTypeFilter) ? 'gray.100' : 'transparent'}
            onClick={() => {
              onTypeChange(type.value as JobTypeFilter);
            }}
          >
            <span>{type.header}</span>
            {type.value === 'Partner_Pickup' &&
              <Badge
                p="2px 8px"
                mx="1"
                fontWeight="medium"
                borderRadius="8px"
                fontSize="x-small"
                background={!!insights?.unassignedPartnerPickup ? 'red' : undefined}
                color={!!insights?.unassignedPartnerPickup ? 'white' : undefined}
              >
                {insights?.unassignedPartnerPickup || 0}
              </Badge>
            }
          </Button>
        ))}
      </HStack>
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
        mr="2"
      >
        {StatsMocking.map((stat) => (
          <Button
            key={stat.header}
            variant="grouped"
            bg={
              statusFilter?.length === 1 && statusFilter?.includes(stat?.value as JobStatusFilter)
                ? 'gray.100'
                : 'transparent'
            }
            onClick={() => {
              onStatusChange(stat.value as JobStatusFilter);
            }}
          >
            {stat.header}
          </Button>
        ))}
      </HStack>
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
        mr="2"
      >
        <Button
          key={'express'}
          variant="grouped"
          bg={isExpressFilter ? 'gray.100' : 'transparent'}
          onClick={() => {
            onExpressFilter(!isExpressFilter);
          }}
        >
          Express
        </Button>
      </HStack>
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
        mr="2"
      >
        <Button
          key={'chilled'}
          variant="grouped"
          bg={hasChilledFilter ? 'gray.100' : 'transparent'}
          onClick={() => {
            onChilledFilter(!hasChilledFilter);
          }}
        >
          Chilled
        </Button>
      </HStack>
    </Flex>
  );
}
