import { useMutation } from '@apollo/client';
import {
  MARK_ORDER_AS_RTO,
  FILTER_ORDERS,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { type clientOptionType } from '../SharedTypes';
import { type Order } from './orders.types';

interface MarkOrderAsRTOResponse {
  message: string;
  data: Order[];
}

interface MarkOrdersAsRtoInput {
  orderIds: string[];
  reason: string;
}

interface MarkOrderAsRTOProps {
  payload: {
    markOrdersAsRtoInput: MarkOrdersAsRtoInput;
  };
  onCompleted: (
    data: { markOrderAsRTO: MarkOrderAsRTOResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useMarkOrderAsRTO() {
  const [mutate, { loading, error }] = useMutation<
    { markOrderAsRTO: MarkOrderAsRTOResponse },
    { markOrdersAsRtoInput: MarkOrdersAsRtoInput }
  >(MARK_ORDER_AS_RTO);

  async function markOrder({ payload, onCompleted }: MarkOrderAsRTOProps) {
    await mutate({
      variables: payload,
      onCompleted,
    });
  }

  return {
    markOrder,
    loading,
    error,
  };
}
