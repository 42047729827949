import { useMutation } from '@apollo/client';
import {
  FILTER_ORDERS,
  RECEIVE_ORDERS,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';

interface ReceiveOrdersResponse {
  message: string;
}

export interface ReceiveOrdersInput {
  ordersSwftboxTracking: string[];
  isForced?: boolean;
}

interface ReceiveOrdersProps {
  receiveOrdersInput: ReceiveOrdersInput;
  onCompleted: (message: string) => void;
}

export function useReceiveOrders() {
  const [mutate, { loading, error }] = useMutation<
    { receiveOrders: ReceiveOrdersResponse },
    { receiveOrdersInput: ReceiveOrdersInput }
  >(RECEIVE_ORDERS);

  async function receiveOrders({ receiveOrdersInput, onCompleted }: ReceiveOrdersProps) {
    await mutate({
      variables: {
        receiveOrdersInput,
      },
      onCompleted: (data) => {
        onCompleted(data.receiveOrders.message);
      },
    });
  }

  return {
    receiveOrders,
    loading,
    error,
  };
}
