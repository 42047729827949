import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  HStack,
  Box,
  useDisclosure,
} from '@swftbox/style-guide';
import { useCallback, useEffect, useState } from 'react';
import { SortingModeContent } from './SortingModeContent';
import { ScanOrdersContent } from './ScanOrdersContent';
import { useScanningMode, type Order } from 'src/components/Particles';

export function UpgradedScan() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [order, setOrder] = useState<Order>();

  const { scannedValue, clearScannedValue } = useScanningMode();

  useEffect(() => {
    // it can include rest of actions with extra handling
    if (scannedValue === 'start_scanning_0_1' || scannedValue?.startsWith('BIN')) {
      onOpen();
    }
  }, [onOpen, scannedValue]);

  const closeModal = useCallback(() => {
    clearScannedValue();
    onClose();
  }, [onClose, clearScannedValue]);

  return (
    <>
      <Button bg="#63C3EC" color="#fff" onClick={onOpen} fontWeight="bolder" fontSize="x-small">
        Super Scan
      </Button>

      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent
          p="0"
          borderRadius="18px"
          overflow="hidden"
          maxW="90vw"
          maxH="90vh"
          w="90vw"
          h="auto"
        >
          <ModalBody p="4" mb="0">
            <HStack spacing="4" alignItems="center" justifyContent="space-between" w="100%">
              <Box w="40%" bg="white" borderRadius="12px" boxShadow="md">
                <SortingModeContent order={order} />
              </Box>

              <Box w="60%" p="4" bg="white" borderRadius="12px" boxShadow="md">
                <ScanOrdersContent onClose={closeModal} setOrder={setOrder} />
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
