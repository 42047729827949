import { Badge, HStack, Td, Text, Tr } from '@swftbox/style-guide';
import { type FC } from 'react';
import { type DropProfile } from 'src/components/Particles';
import { ActionButtons } from '../DropProfileDetails/ActionButtons';

interface DropProfileProps {
  dropProfile: DropProfile;
  addDivider?: boolean;
  onEditDropProfile: (dropProfile: DropProfile) => void;
  onViewDetails: (dropProfile: DropProfile) => void;
  retailerId: string;
  groupId: string;
}

const DropProfileRow: FC<DropProfileProps> = ({
  dropProfile,
  addDivider,
  onEditDropProfile,
  onViewDetails,
  retailerId,
  groupId,
}) => {
  return (
    <Tr
      key={dropProfile.id}
      title={dropProfile.description}
      style={addDivider ? { borderBottom: '3px solid' } : {}}
    >
      <Td>
        <Text>{dropProfile.name}</Text>
      </Td>
      <Td>
        <Text>{dropProfile.profileType}</Text>
      </Td>
      <Td>
        <Text maxW="100%" fontWeight="semibold">
          {dropProfile.cutoffTime.replace(/:00$/, '')}
        </Text>
      </Td>
      <Td>
        <Text>{dropProfile.cities.length}</Text>
      </Td>

      <Td>
        <HStack spacing={2}>
          {dropProfile.isPrimary && (
            <Badge
              p="2px 8px"
              fontWeight="medium"
              borderRadius="8px"
              textAlign={'center'}
              fontSize="text-xs"
              variant="solid"
              colorScheme="blue"
            >
              {dropProfile.isReturn ? 'Primary RTN' : 'Primary'}
            </Badge>
          )}
          {!dropProfile.isActive && (
            <Badge
              p="2px 8px"
              fontWeight="medium"
              borderRadius="8px"
              textAlign={'center'}
              fontSize="text-xs"
              variant="solid"
              colorScheme="red"
            >
              Disabled
            </Badge>
          )}
        </HStack>
      </Td>

      <Td>
        <ActionButtons
          dropProfile={dropProfile}
          onViewDetails={onViewDetails}
          onEdit={onEditDropProfile}
          retailerId={retailerId}
          groupId={groupId}
        />
      </Td>
    </Tr>
  );
};

export default DropProfileRow;
