import { useMutation } from '@apollo/client';
import { removeTypeName } from '../../Helpers';
import { type EditCustomerAddressType } from '../../Modules/OrderModule/types';
import { type MessageResponse } from '../SharedTypes';
import { UPDATE_LOCATION } from './location.graphql';
import { FILTER_ORDERS, GET_ORDER_ADDRESS_HISTORY } from '../orders/orders.graphql';
import { QueriesStore } from '../queriesStore';

interface UpdateLocationProps {
  payload: EditCustomerAddressType;
  onCompleted?: (message: string) => void;
}

export function useUpdateLocation() {
  const [mutate, { loading, error }] = useMutation<
    { updateLocation: MessageResponse },
    { updateLocationInput: EditCustomerAddressType }
  >(UPDATE_LOCATION);

  async function updateLocation({ payload, onCompleted }: UpdateLocationProps) {
    await mutate({
      variables: {
        updateLocationInput: removeTypeName(payload),
      },
      onCompleted: ({ updateLocation }) => {
        if (onCompleted) onCompleted(updateLocation.message);
      },
      // Missing Dto
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
        { query: GET_ORDER_ADDRESS_HISTORY, variables: { locationId: payload.id } },
      ],
    });
  }

  return {
    updateLocation,
    loading,
    error,
  };
}
