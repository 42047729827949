import { useMutation } from '@apollo/client';
import { START_PACKING, FILTER_ORDERS } from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';

interface StartPackingResponse {
  message: string;
}

interface StartPackingProps {
  payload: {
    ids: string[];
  };
  onCompleted: (message: string) => void;
}

export function useStartPacking() {
  const [mutate, { loading, error }] = useMutation<
    { updateFulfillmentStatus: StartPackingResponse },
    { updateFulfillmentStatusInput: { orderIds: string[], fulfillmentStatus: string } }
  >(START_PACKING);

  async function startPacking({ payload, onCompleted }: StartPackingProps) {
    await mutate({
      variables: {
        updateFulfillmentStatusInput: {
          orderIds: payload.ids,
          fulfillmentStatus: 'PACKING',
        },
      },
      onCompleted: (data) => {
        onCompleted(data.updateFulfillmentStatus.message);
      },
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
      ],
    });
  }

  return {
    startPacking,
    loading,
    error,
  };
}
