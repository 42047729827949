import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  SBAddIcon,
  SBModal,
  SBMapMarkerIcon,
  useDisclosure,
  toast,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { type AddBinInput, useAddBin } from 'src/components/Particles';
import { CreateBinSchema } from '../../Validations';
import { BinForm } from '../../Form/BinForm';
import { useCallback } from 'react';

export function AddBin() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { addBin } = useAddBin();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<AddBinInput>({
    mode: 'all',
    resolver: yupResolver(CreateBinSchema),
  });

  const handleCloseModal = useCallback(
    (message?: string) => {
      if (message) {
        toast.success(message);
      }
      onClose();
      reset();
    },
    [onClose, reset]
  );

  const onConfirm = useCallback(
    (data: AddBinInput) => {
      void addBin({
        payload: data,
        onCompleted: (data) => {
          handleCloseModal(data.createBin.message);
        },
      });
    },
    [addBin, handleCloseModal]
  );

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add Bin
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBMapMarkerIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
          </Box>
        }
        size="2xl"
        body={<BinForm register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
