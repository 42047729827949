import { useMutation } from '@apollo/client';
import { CREATE_ORDERS_FLAGS, FILTER_ORDERS, FLAGGED_ORDER_COUNTS } from './orders.graphql';
import { type OrdersFlagInput } from './orders.types';
import { QueriesStore } from '../queriesStore';

interface OrderFlagResponse {
  message: string;
}

interface FlagOrdersProps {
  payload: {
    OrderFlagInput: OrdersFlagInput;
  };
  onCompleted: (message: string) => void;
}

const filterOrdersVariables = QueriesStore.get('FILTER_ORDERS');

export function useFlagOrders() {
  const [mutate, { loading, error }] = useMutation<
    { createOrdersFlag: OrderFlagResponse },
    { OrderFlagInput: OrdersFlagInput }
  >(CREATE_ORDERS_FLAGS);

  async function flagOrders({ payload, onCompleted }: FlagOrdersProps) {
    await mutate({
      variables: payload,
      onCompleted: (data) => {
        onCompleted(data.createOrdersFlag.message);
      },
      // Missing Dto
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: filterOrdersVariables,
        },
        { query: FLAGGED_ORDER_COUNTS },
      ],
    });
  }

  return {
    flagOrders,
    loading,
    error,
  };
}
