import { Box, Button, Stack, toast } from '@swftbox/style-guide';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  type Invoice,
  InvoiceLineItemType,
  InvoiceType,
  Retailer,
  UpdateInvoiceInput,
  UpdateInvoiceLineItemInput,
  useUpdateInvoiceMutation,
} from 'src/components/Particles';
import { InvoiceDetails } from './InvoiceDetails';
import { InvoiceFooter } from './InvoiceFooter';
import { InvoiceHeader } from './InvoiceHeader';
import { LineItems } from './LineItems';
import { PaymentInvoiceLineItems } from './PaymentInvoiceLineItems';

interface InvoiceProps {
  retailer: Retailer;
  invoice: Invoice;
  onClose: () => void;
}

interface FieldValues extends UpdateInvoiceInput {
  transactionItems: UpdateInvoiceLineItemInput[];
  adjustments: UpdateInvoiceLineItemInput[];
}

export function Invoice({ retailer, invoice, onClose }: InvoiceProps) {
  const defaultValues: FieldValues = {
    invoiceNumber: invoice.invoiceNumber,
    activeAt: invoice.activeAt,
    transactionItems:
      invoice.lineItems
        ?.filter((item) => item.type === InvoiceLineItemType.TRANSACTION)
        .map((item) => ({
          code: item.code,
          description: item.description,
          id: item.id,
          quantity: item.quantity,
          rate: item.rate,
          vat: item.vat,
          type: item.type,
          taxableAmount: item.taxableAmount,
        })) || [],
    deletedLineItems: [],
    dueDateInDays: invoice.dueDateInDays,
    from: invoice.from,
    id: invoice.id,
    adjustments:
      invoice.lineItems
        ?.filter((item) => item.type === InvoiceLineItemType.ADJUSTMENT)
        .map((item) => ({
          code: item.code,
          description: item.description,
          id: item.id,
          quantity: item.quantity,
          rate: item.rate,
          vat: item.vat,
          type: item.type,
          taxableAmount: item.taxableAmount,
        })) || [],
    lineItems:
      invoice.lineItems
        ?.filter((item) => item.type === InvoiceLineItemType.SERVICE)
        .map((item) => ({
          code: item.code,
          description: item.description,
          id: item.id,
          quantity: item.quantity,
          rate: item.rate,
          vat: item.vat,
          type: item.type,
          taxableAmount: item.taxableAmount,
        })) || [],
    poNumber: invoice.poNumber !== 'Add PO Number' ? invoice.poNumber : '',
    to: invoice.to,
    status: invoice.status,
  };

  const methods = useForm<FieldValues>({
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const { mutate, loading } = useUpdateInvoiceMutation({
    onCompleted: (data) => {
      if (data.updateInvoice.data.id) {
        toast.success('Invoice updated successfully');
      } else {
        toast.error('Invoice update failed');
      }
      reset(defaultValues);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: FieldValues) => {
      if (!data.invoiceNumber || data.invoiceNumber.length > 50 ) 
      {
        toast.error('Invoice number is required and should be less than 50 characters');
        return;
      }
      const confirmed = confirm('Are you sure you want to save you changes?');
      if (!confirmed) {
        return;
      }
      const dataItems = [...data.lineItems, ...data.transactionItems, ...data.adjustments];

      const dataItemIds = new Set(dataItems.map((item) => item.id).filter((itemId) => !!itemId));

      const deletedLineItems = invoice.lineItems
        ?.filter((invoiceItem) => !dataItemIds.has(invoiceItem.id))
        .map((lineItem) => lineItem.id);

      mutate({
        variables: {
          updateInvoiceInput: {
            invoiceNumber: data.invoiceNumber,
            activeAt: data.activeAt,
            ...(deletedLineItems?.length && { deletedLineItems }),
            dueDateInDays: data.dueDateInDays,
            from: data.from,
            id: data.id,
            lineItems: [
              ...([...data.lineItems, ...data.transactionItems, ...data.adjustments]?.map(
                (item) => ({
                  code: item.code,
                  description: item.description,
                  id: item.id,
                  quantity: item.quantity,
                  rate: item.rate,
                  vat: item.vat,
                  type: item.type,
                  taxableAmount: item.rate * item.quantity,
                })
              ) || []),
            ],
            ...(!!data.poNumber &&
              data.poNumber !== 'Add PO Number' && { poNumber: data.poNumber }),
            to: data.to,
            status: data.status,
          },
        },
      });
    },
    [mutate]
  );

  return (
    <FormProvider {...methods}>
      <Box as="form" py={2} rounded="md" bg="white" onSubmit={handleSubmit(onSubmit)}>
        <InvoiceHeader retailerName={retailer.name} billingInfo={retailer.billingInfo} />
        <InvoiceDetails invoice={invoice} />
        {invoice.type === InvoiceType.COLLECTION ? (
          <PaymentInvoiceLineItems invoiceData={invoice} />
        ) : (
          <LineItems invoiceData={invoice} />
        )}
        <InvoiceFooter />
        <Stack mt={5} spacing="3" w="100%" direction={['column', 'row']} position={'sticky'}>
          <Button variant="outline" w="100%" onClick={onClose}>
            Cancel
          </Button>
          <Button w="100%" type="submit" isLoading={loading}>
            Save
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
}
