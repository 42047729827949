import { gql } from '@apollo/client';
import { CORE_COMMENTS_FRAGMENT } from '../comments/comments.graphql';

export const CORE_TO_AND_FROM_DATA = gql`
  fragment CoreToAndFromData on LocationDto {
    id
    name
    dispatchZone
    dispatchCity
    country
    countryCode
    city
    addressLine1
    addressLine2
    addressType
    area
    street
    building
    landMark
    company
    stateProvince
    stateProvinceCode
    zipPostalCode
    isDefault
    isVerified
    verificationSource
    isInternational
    isRemote
    isOutOfZone
    entityId
    entityType
    coordinates {
      longitude
      latitude
    }
    phone
    stateProvince
    zipPostalCode
  }
`;

export const CORE_ORDER_DATA = gql`
  ${CORE_TO_AND_FROM_DATA}
  ${CORE_COMMENTS_FRAGMENT}
  fragment CoreOrderData on OrderDto {
    id
    reference
    swftboxTracking
    retailerTracking
    brandName
    paymentMode
    paymentAmount
    isReverse
    state
    stage
    status
    fulfillmentStatus
    shippingFlag
    failedAttemptCount
    arrivingShortly
    b2bHandling
    b2bInvoice
    oversized
    chilled
    validationStatus
    packageCount
    requireDeliveryNote
    requireProofOfId
    allowCustomerProofSignature
    shouldLeaveAtTheDoor
    scheduledCustomerSlot {
      date
      from
      to
    }
    isExpress
    createdAt
    updatedAt
    notes
    fulfillmentRemarks
    routeId
    driverName
    driverPhone
    attachments
    integration
    comments {
      ...commentFragment
    }
    customer {
      id
      name
      phone
      email
    }
    retailer {
      id
      name
      phone
      logo
      shippingLabelSize
      showBrand
      procedures {
        id
        type
        description
        images
        videos
      }
    }
    timing {
      inputDate
      actualPickupDateStart
      actualPickupDateEnd
      actualDropDateStart
      actualDropDateEnd
      expectedPickupDateStart
      expectedPickupDateEnd
      expectedDropDateStart
      expectedDropDateEnd
      slaStart
      slaEnd
    }
    from {
      ...CoreToAndFromData
    }
    to {
      ...CoreToAndFromData
    }
    groupType
    groupId
    originalOrderId
    groupedOrders {
      id
      swftboxTracking
      reference
      isReverse
      notes
      fulfillmentRemarks
      state
      status
      partnerStatus
      packageCount
    }
    items {
      id
      quantity
      price
      weight
      weightUnit
      description
    }
    payment {
      status
    }
    currency
    deliveryNoteUrls
    proofOfId
    customerProofSignatureUrl
    isBoosted
    isInternational
    isRemote
    isOutOfZone
    orderFlags {
      id
      flagType
      flagReason
      status
    }
    internationalDetails {
      carrierId
      carrierName
      status
      shippingLabelUrl
      trackingUrl
      commercialInvoiceUrl
      estimatedShippingCost
      estimatedDeliveryDate
      isAssigned
      errors {
        level
        trigger
        source
        type
        message
      }
    }
    requireOtpOnDelivery
  }
`;

export const GET_ORDERS = gql`
  ${CORE_TO_AND_FROM_DATA}
  query GetOrders(
    $page: Int
    $perPage: Int
    $stages: [OrderStages!]
    $states: [OrderStates!]
    $startDate: DateTime
    $endDate: DateTime
    $order: OrderSort
    $orderBy: String
  ) {
    paginatedOrders(
      page: $page
      perPage: $perPage
      stages: $stages
      states: $states
      startDate: $startDate
      endDate: $endDate
      order: $order
      orderBy: $orderBy
    ) {
      data {
        id
        reference
        swftboxTracking
        retailerTracking
        brandName
        paymentMode
        paymentAmount
        isReverse
        state
        stage
        status
        fulfillmentStatus
        shippingFlag
        failedAttemptCount
        arrivingShortly
        b2bHandling
        b2bInvoice
        oversized
        chilled
        validationStatus
        packageCount
        requireDeliveryNote
        requireProofOfId
        allowCustomerProofSignature
        requireOtpOnDelivery
        scheduledCustomerSlot {
          date
          from
          to
        }
        isExpress
        createdAt
        updatedAt
        notes
        fulfillmentRemarks
        integration
        dropProfile {
          name
          cities {
            city
          }
        }
        customer {
          id
          name
          phone
          email
        }
        retailer {
          id
          name
          phone
          logo
          procedures {
            id
            type
            description
            images
            videos
          }
        }
        timing {
          inputDate
          actualPickupDateStart
          actualPickupDateEnd
          actualDropDateStart
          actualDropDateEnd
          expectedPickupDateStart
          expectedPickupDateEnd
          expectedDropDateStart
          expectedDropDateEnd
        }
        from {
          ...CoreToAndFromData
          identifier
        }
        to {
          ...CoreToAndFromData
          identifier
        }
        groupType
        groupId
        originalOrderId
        groupedOrders {
          id
          swftboxTracking
          reference
          isReverse
          notes
          fulfillmentRemarks
          state
          status
          partnerStatus
          packageCount
        }
        items {
          id
          quantity
          price
          weight
          weightUnit
          description
        }
        currency
      }
      total
      pageCount
    }
  }
`;

export const FILTER_ORDERS = gql`
  ${CORE_ORDER_DATA}
  query FilterOrders(
    $search: String
    $shippingFlag: OrderShippingFlags
    $states: [OrderStates!]
    $stages: [OrderStages!]
    $status: [OrderStatus!]
    $failedAttemptCount: Float
    $validationStatus: [OrderValidationStatus!]
    $stopStatus: [StopStatus!]
    $orderBy: OrderSortFields
    $order: ElasticsearchOrderSort
    $or: [ElasticOrderOrClauseFilter!]
    $partnerCategoryIds: [UUID!]
    $dispatchZone: [String!]
    $toCity: [String!]
    $fromCity: [String!]
    $toAddress: String
    $fromAddress: String
    $retailerId: [UUID!]
    $retailerEmail: String
    $retailerPhone: String
    $retailerName: String
    $customerEmail: String
    $customerPhone: String
    $customerName: String
    $brandName: String
    $retailerTracking: String
    $swftboxTracking: String
    $reference: String
    $driverId: [UUID!]
    $isInternational: Boolean
    $isRemote: Boolean
    $chilled: Boolean
    $dateRange: DateRangeInput
    $flagType: [OrderFlagType!]
    $groupType: [OrderGroupType!]
    $not: ElasticOrderNotClauseFilter
    $perPage: Int
    $page: Int
  ) {
    filterOrders(
      search: $search
      shippingFlag: $shippingFlag
      states: $states
      stages: $stages
      status: $status
      failedAttemptCount: $failedAttemptCount
      validationStatus: $validationStatus
      stopStatus: $stopStatus
      orderBy: $orderBy
      order: $order
      or: $or
      partnerCategoryIds: $partnerCategoryIds
      dispatchZone: $dispatchZone
      toCity: $toCity
      fromCity: $fromCity
      toAddress: $toAddress
      fromAddress: $fromAddress
      retailerId: $retailerId
      retailerEmail: $retailerEmail
      retailerPhone: $retailerPhone
      retailerName: $retailerName
      customerEmail: $customerEmail
      customerPhone: $customerPhone
      customerName: $customerName
      brandName: $brandName
      retailerTracking: $retailerTracking
      swftboxTracking: $swftboxTracking
      reference: $reference
      driverId: $driverId
      isInternational: $isInternational
      isRemote: $isRemote
      chilled: $chilled
      dateRange: $dateRange
      flagType: $flagType
      groupType: $groupType
      not: $not
      perPage: $perPage
      page: $page
    ) {
      data {
        ...CoreOrderData
        dropProfile {
          key
          profileType
          name
        }
        scheduledPickupSlot {
          from
          date
        }
      }
      total
      pageCount
    }
  }
`;

export const GET_SIMPLE_ORDERS = gql`
  ${CORE_ORDER_DATA}
  query GetOrders(
    $page: Int
    $perPage: Int
    $stages: [OrderStages!]
    $states: [OrderStates!]
    $startDate: DateTime
    $endDate: DateTime
    $shippingFlag: OrderShippingFlags
  ) {
    paginatedOrders(
      page: $page
      perPage: $perPage
      stages: $stages
      states: $states
      startDate: $startDate
      endDate: $endDate
      shippingFlag: $shippingFlag
    ) {
      data {
        ...CoreOrderData
      }
      total
      pageCount
    }
  }
`;

export const SEARCH_ORDERS = gql`
  ${CORE_ORDER_DATA}
  query SearchOrders($text: String!, $perPage: Int, $page: Int) {
    searchOrders(text: $text, perPage: $perPage, page: $page) {
      data {
        ...CoreOrderData
      }
      total
      pageCount
    }
  }
`;

export const GET_ORDER = gql`
  ${CORE_ORDER_DATA}
  query Order($orderId: UUID!) {
    Order(id: $orderId) {
      ...CoreOrderData
      originalPayload
    }
  }
`;

export const GET_ORDER_SWFTBOX_TRACKING = gql`
  ${CORE_ORDER_DATA}
  query OrderBySwftboxTracking($swftboxTracking: String!) {
    OrderBySwftboxTracking(swftboxTracking: $swftboxTracking) {
      ...CoreOrderData
      dropProfile {
        profileType
      }
      orderFlags {
        flagType
        flagReason
        status
      }
    }
  }
`;

export const GET_ORDER_TRACKING_NUMBER = gql`
  ${CORE_ORDER_DATA}
  query OrderByTrackingNumber($trackingNumber: String!) {
    OrderByTrackingNumber(trackingNumber: $trackingNumber) {
      ...CoreOrderData
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($orderIds: IdsInput!) {
    cancelOrder(orderIds: $orderIds) {
      message
    }
  }
`;

export const MARK_ORDER_AS_RTO = gql`
  ${CORE_ORDER_DATA}
  mutation MarkOrderAsRTO($markOrdersAsRtoInput: MarkOrdersAsRTOInput!) {
    markOrderAsRTO(MarkOrdersAsRTOInput: $markOrdersAsRtoInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const UNDO_RTO = gql`
  ${CORE_ORDER_DATA}
  mutation UndoRTO($orderIds: IdsInput!) {
    undoRTO(orderIds: $orderIds) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const ASSIGN_ORDER = gql`
  mutation Mutation($quickRouteInput: CreateQuickRouteInput!) {
    createQuickRoute(quickRouteInput: $quickRouteInput) {
      message
      data {
        id
        name
        driverId
        status
        driver {
          user {
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  ${CORE_ORDER_DATA}
  mutation UpdateOrder($updateOrderInput: UpdateOrderInput!) {
    updateOrder(updateOrderInput: $updateOrderInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const UPDATE_ORDER_PAYMENT = gql`
  ${CORE_ORDER_DATA}
  mutation UpdateOrderPayment($updateOrderPaymentInput: UpdateOrderPaymentInput!) {
    updateOrderPayment(updateOrderPaymentInput: $updateOrderPaymentInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const RECEIVE_ORDERS = gql`
  ${CORE_ORDER_DATA}
  mutation ReceiveOrders($receiveOrdersInput: ReceiveOrdersInput!) {
    receiveOrders(ReceiveOrdersInput: $receiveOrdersInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const PUSH_ORDERS_TO_DISPATCH = gql`
  mutation PushToDispatch($orderIds: IdsInput!) {
    pushToDispatch(orderIds: $orderIds) {
      message
    }
  }
`;

export const START_PACKING = gql`
  mutation StartPacking($updateFulfillmentStatusInput: UpdateFulfillmentStatusInput!) {
    updateFulfillmentStatus(updateFulfillmentStatusInput: $updateFulfillmentStatusInput) {
      message
    }
  }
`;

export const INSIGHTS = gql`
  query RetailerInsights {
    retailerInsights {
      todayDelivered
      todaySuccessRate
      yesterdaySuccessRate
      yesterdayDelivered
      thisMonthDelivered
      thisMonthSuccessRate
    }
  }
`;

export const COUNTS = gql`
  query SwftboxOrdersCounts {
    SwftboxOrdersCounts {
      counts
    }
  }
`;

export const RESCHEDULE_ORDER = gql`
  ${CORE_ORDER_DATA}
  mutation RescheduleOrder($rescheduleOrderInput: RescheduleOrderInput!) {
    rescheduleOrder(rescheduleOrderInput: $rescheduleOrderInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const ORDER_TIMELINE = gql`
  query OrderTimeline($orderId: UUID!) {
    orderTimeline(orderId: $orderId) {
      orderId
      swftboxTracking
      state
      stage
      status
      userId
      custodianId
      custodianType
      action
      description
      partnerDescription
      date
      isMilestone
      failedAttempt
      failedAttemptReason
      orderUpdates
      actionByUser {
        id
        createdAt
        updatedAt
        deletedAt
        name
        email
        phone
        status
        roles
        permissions
      }
      customer {
        name
      }
      dispatcher {
        name
      }
      driver {
        name
      }
      retailer {
        name
      }
      fulfillment {
        id
        createdAt
        updatedAt
        deletedAt
        name
        location {
          name
        }
      }
    }
  }
`;

export const FORCE_DELIVER_ORDERS = gql`
  ${CORE_ORDER_DATA}
  mutation ForceDeliver($customerForceDropInput: CustomerForceDropInput!) {
    forceDeliver(customerForceDropInput: $customerForceDropInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const FORCE_RTO_COMPLETE_ORDERS = gql`
  ${CORE_ORDER_DATA}
  mutation ForceRtoComplete($forceRtoCompleteInput: ForceRtoCompleteInput!) {
    forceRtoComplete(forceRtoCompleteInput: $forceRtoCompleteInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const FORCE_BACK_TO_REQUESTED = gql`
  ${CORE_ORDER_DATA}
  mutation ForceToRequested($forceToRequestedInput: ForceToRequestedInput!) {
    forceToRequested(forceToRequestedInput: $forceToRequestedInput) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const SYNC_ORDERS = gql`
  mutation FixOrdersElasticSync($fixElasticOrdersInput: FixElasticOrdersInput!) {
    fixOrdersElasticSync(fixElasticOrdersInput: $fixElasticOrdersInput) {
      success
      message
    }
  }
`;

export const UPDATE_ORDER_DROP_PROFILE = gql`
  mutation UpdateOrderDropProfile($updateOrderDropProfileInput: UpdateOrderDropProfileInput!) {
    updateOrderDropProfile(updateOrderDropProfileInput: $updateOrderDropProfileInput) {
      message
      data {
        swftboxTracking
        dropProfileId
        dropProfile {
          key
          profileType
          name
        }
        timing {
          inputDate
          actualPickupDateStart
          actualPickupDateEnd
          actualDropDateStart
          actualDropDateEnd
          expectedPickupDateStart
          expectedPickupDateEnd
          expectedDropDateStart
          expectedDropDateEnd
          slaStart
          slaEnd
        }
      }
    }
  }
`;

export const CREATE_ORDER_FLAG = gql`
  mutation CreateOrderFlag($orderFlagInput: OrderFlagInput!) {
    createOrderFlag(OrderFlagInput: $orderFlagInput) {
      message
      data {
        id
        createdAt
        updatedAt
        deletedAt
        orderId
        retailerId
        flagType
        flagReason
        status
        resolution
        flaggedBy
        resolvedBy
        resolvedAt
      }
    }
  }
`;

export const UPDATE_ORDER_FLAG = gql`
  mutation UpdateOrderFlag($updateOrderFlagInput: UpdateOrderFlagInput!) {
    updateOrderFlag(updateOrderFlagInput: $updateOrderFlagInput) {
      message
      data {
        id
        createdAt
        updatedAt
        deletedAt
        orderId
        retailerId
        flagType
        flagReason
        status
        resolution
        flaggedBy
        resolvedBy
        resolvedAt
      }
    }
  }
`;

export const FAIL_RETURN_ORDERS = gql`
  ${CORE_COMMENTS_FRAGMENT}
  mutation FailReturnOrders($failedReturnInput: FailedReturnInput!) {
    failReturnOrders(failedReturnInput: $failedReturnInput) {
      message
      data {
        id
        swftboxTracking
        status
        comments {
          ...commentFragment
        }
      }
    }
  }
`;

export const FLAGGED_ORDER_COUNTS = gql`
  query FlaggedOrdersCount {
    flaggedOrdersCount {
      flagged
      csCheck
      starred
    }
  }
`;

export const ORDER_INSIGHTS = gql`
  query OrderInsights {
    getOrderInsights {
      pickupRequested
      flagged
      csCheck
      starred
    }
  }
`;

export const GET_ORDERS_MILESTONE = gql`
  query OrdersMilestones($orderIds: IdsInput!) {
    ordersLatestMilestones(orderIds: $orderIds) {
      isMilestone
      orderId
      action
      date
      driver {
        name
      }
      description
    }
  }
`;

export const GET_ORDER_ADDRESS_HISTORY = gql`
  query LocationTimelines($locationId: UUID!) {
    locationTimelines(locationId: $locationId) {
      action
      dispatchCity
      dispatchZone
      locationUpdates
      updatedAt
      user {
        name
      }
    }
  }
`;

export const GET_TIMELINE_FILTER = gql`
  query OrdersOverview(
    $from: DateTime!
    $to: DateTime!
    $status: [OrderStatus!]
    $stopStatus: [StopStatus!]
    $validationStatus: [OrderValidationStatus!]
    $fromCity: [String!]
    $toCity: [String!]
    $or: [ElasticOrderOrClauseFilter!]
    $driverId: [UUID!]
  ) {
    ordersOverview(
      from: $from
      to: $to
      status: $status
      stopStatus: $stopStatus
      validationStatus: $validationStatus
      fromCity: $fromCity
      toCity: $toCity
      or: $or
      driverId: $driverId
    ) {
      warningTime
      warningCount
      pastCount
      futureCount
      date
      dangerTime
      dangerCount
      hourlyHistogram {
        value
        count
        expressCount
      }
    }
  }
`;

export const CARRIYO_INTEGRATION_CARRIERS = gql`
  query CarriyoIntegrationCarriers {
    carriyoIntegrationCarriers {
      id
      createdAt
      updatedAt
      accountName
      accountId
      isActive
    }
  }
`;

export const CREATE_CARRIYO_INTEGRATION_CARRIER = gql`
  mutation CreateCarriyoIntegrationCarrier(
    $createCarriyoIntegrationCarrierInput: CreateCarriyoIntegrationCarrierInput!
  ) {
    createCarriyoIntegrationCarrier(
      createCarriyoIntegrationCarrierInput: $createCarriyoIntegrationCarrierInput
    ) {
      message
    }
  }
`;

export const UPDATE_CARRIYO_INTEGRATION_CARRIER = gql`
  mutation UpdateCarriyoIntegrationCarrier(
    $updateCarriyoIntegrationCarrierInput: UpdateCarriyoIntegrationCarrierInput!
  ) {
    updateCarriyoIntegrationCarrier(
      updateCarriyoIntegrationCarrierInput: $updateCarriyoIntegrationCarrierInput
    ) {
      message
    }
  }
`;

export const ASSIGN_ORDER_INTERNATIONAL = gql`
  ${CORE_ORDER_DATA}
  mutation AssignOrderInternational($assignOrderInternational: AssignOrderInternationalInput!) {
    assignOrderInternational(assignOrderInternational: $assignOrderInternational) {
      message
      data {
        ...CoreOrderData
      }
    }
  }
`;

export const CANCEL_ORDER_INTERNATIONAL = gql`
  mutation CancelOrderInternational($swftboxTracking: String!) {
    cancelOrderInternational(swftboxTracking: $swftboxTracking) {
      message
    }
  }
`;

export const FETCH_INTERNATIONAL_ORDER_STATUS = gql`
  mutation FetchInternationalOrderStatus($swftboxTracking: String!) {
    fetchInternationalOrderStatus(swftboxTracking: $swftboxTracking) {
      success
      message
    }
  }
`;

export const TOGGLE_ORDERS_BINS = gql`
  mutation ToggleOrdersBin($toggleOrdersToBinInput: ToggleOrdersToBinInput!) {
    toggleOrdersBin(toggleOrdersToBinInput: $toggleOrdersToBinInput) {
      message
    }
  }
`;

export const GET_ORDERS_BY_BIN_LABEL = gql`
  ${CORE_ORDER_DATA}
  query BinByLabel($label: String!) {
    binByLabel(label: $label) {
      id
      name
      description
      location
      dispatchZone
      label
      orders {
        ...CoreOrderData
      }
    }
  }
`;

export const CREATE_ORDERS_FLAGS = gql`
  mutation CreateOrdersFlag($OrderFlagInput: OrdersFlagsInput!) {
    createOrdersFlag(OrderFlagInput: $OrderFlagInput) {
      message
    }
  }
`;
