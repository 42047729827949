import {
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { Invoice } from 'src/components/Particles';
import styles from '../../style.module.scss';

interface InvoiceDetailsProps {
  invoice: Invoice;
}

export const InvoiceDetails = ({ invoice }: InvoiceDetailsProps) => {
  const { control } = useFormContext();
  return (
    <Stack mt={10}>
      <HStack justify={'space-between'} align={'flex-start'}>
        <VStack align={'start'}>
          <Text>Invoice Date</Text>
          <Text>{format(new Date(invoice.activeAt), 'dd-MMMM-yy')}</Text>
          <Spacer />
          <Text>Invoice Number</Text>
          <Text>
            <Controller
              name={`invoiceNumber`}
              control={control}
              defaultValue={
                invoice.invoiceNumber === '' ? 'Add Invoice Number' : invoice.invoiceNumber
              }
              render={({ field }) => (
                <Editable
                  defaultValue={field.value}
                  onSubmit={(value) => {
                    field.onChange(value);
                  }}
                >
                  <EditablePreview className={styles.editableFieldPreview} />
                  <EditableInput className={styles.editableFieldInput} />
                </Editable>
              )}
            />
          </Text>
          <Spacer />
          <Text>Invoice PO Number</Text>
          <Text>
            {' '}
            <Controller
              name={`poNumber`}
              control={control}
              defaultValue={invoice.poNumber === '' ? 'Add PO Number' : invoice.poNumber}
              render={({ field }) => (
                <Editable
                  defaultValue={field.value}
                  onSubmit={(value) => {
                    field.onChange(value);
                  }}
                >
                  <EditablePreview className={styles.editableFieldPreview} />
                  <EditableInput className={styles.editableFieldInput} />
                </Editable>
              )}
            />
          </Text>
          <Spacer />
          <Text>Reference</Text>
        </VStack>
        <VStack align={'end'}>
          <Text>Invoice Period</Text>
          <Text>{`${format(new Date(invoice.from), 'dd-MMMM-yy')} to ${format(
            new Date(invoice.to),
            'dd-MMMM-yy'
          )}`}</Text>
        </VStack>
      </HStack>
    </Stack>
  );
};
